import { ProjectSearchProperties, SearchAllProjectColumnsDropdownMenuItemValue } from "./ProjectsPage.constants"
import { L10n } from "@encoway/l10n"
import { ChangeEvent } from "react"
import { Characteristic } from "@encoway/c-services-js-client"
import { DropdownMenuItem } from "../../components/dropdown/Dropdown"
import TranslationKeys from "../../features/translations/TranslationKeys"

export function getProjectSearchColumns(salesDocumentPropertyCharacteristics: Characteristic[]): DropdownMenuItem[] {
    const columns = (ProjectSearchProperties as string[]).map(property => ({
        value: property,
        text: salesDocumentPropertyCharacteristics.find(characteristic => characteristic.id === property)?.name ?? property
    }))
    columns.unshift({ value: SearchAllProjectColumnsDropdownMenuItemValue, text: L10n.format(TranslationKeys.pages.projects.searchDropdownSearchAllOption) })
    return columns
}

export const searchColumnsReducer = (state: string[], event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value as string | string[]
    const allOption = SearchAllProjectColumnsDropdownMenuItemValue
    if (typeof value === "string") {
        return value.split(",")
    }
    if (!state.includes(allOption) && value.includes(allOption)) {
        return [allOption]
    }
    return value.filter(option => option !== allOption)
}
