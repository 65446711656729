import * as oauth from "oauth4webapi"
import { AuthorizationServer, OpenIDTokenEndpointResponse } from "oauth4webapi"
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react"
import { v4 as uuidv4 } from "uuid"

import {
    clearAuthData,
    getAccessToken,
    getAuthenticationServer,
    getClearUrl,
    IAuthData,
    IAuthResponse,
    IAuthValidity,
    isRedirectedFromAuthServer,
    OidcClientSettings,
    redirectToAuthorizationServer,
    refreshAccessToken,
    restoreAuthData,
    restoreAuthInitializationData,
    restoreAuthSearch,
    storeAuthInitializationData,
    storeAuthSearch,
    storeAuthToken
} from "./helper"
import { IOpenIdArgs, IOpenIdTokenFunction } from "@encoway/sales-showroom-auth"

interface IRefreshableOpenIdTokenFunction extends IOpenIdTokenFunction {
    refresh: () => Promise<void>
}

/**
 * Obtained from product code: oid.hook.ts.
 * @param args .
 * @param skip .
 */
export const useAbbOpenIdAuthentication = (args: IOpenIdArgs, skip?: boolean) => {
    const [tokenFunction, setTokenFunction] = useState<IRefreshableOpenIdTokenFunction>()
    const [authenticationError, setAuthenticationError] = useState<Error>()
    const authenticationData = useRef<IAuthResponse>()
    const authenticationValidity = useMemo<IAuthValidity>(() => {
        return {
            needsRefresh: false,
            refreshableUntil: 0,
            validUntil: 0
        }
    }, [])

    async function startAuthentication(authServerUrl: string, client: oauth.Client, as: AuthorizationServer, codeChallengeMethod: string) {
        const oidcClient: OidcClientSettings = {
            clientId: args.clientId,
            redirectUri: args.redirectUri ?? getClearUrl(),
            realm: args.realm,
            authServerUrl,
            clientSecret: args.clientSecret
        }
        const codeVerifier = oauth.generateRandomCodeVerifier()
        const codeChallenge = await oauth.calculatePKCECodeChallenge(codeVerifier)
        const randomValue = new Uint32Array(1)
        crypto.getRandomValues(randomValue)
        const nonce = `${uuidv4()}_${randomValue}`
        storeAuthInitializationData(oidcClient, codeVerifier, codeChallenge, window.location.href, nonce)
        redirectToAuthorizationServer(oidcClient, client, as, codeChallenge, codeChallengeMethod, nonce)
    }

    async function redirectedFromAuthServer(as: AuthorizationServer, client: oauth.Client) {
        try {
            const [oidcClient, url, codeVerifier, , nonce] = restoreAuthInitializationData()
            const token = await getAccessToken(as, client, oidcClient.redirectUri, codeVerifier, nonce)
            const search = window.location.search
            storeAuthSearch(search)
            updateStoredAuthenticationData(token, authenticationData, authenticationValidity)
            setTokenFunction(createTokenObj(as, client, authenticationData, authenticationValidity, search, nonce))
            window.history.replaceState(null, "", url)
        } catch (e) {
            setAuthenticationError(e as Error)
        }
    }

    async function foundExistingToken(
        token: IAuthData,
        authServerUrl: string,
        client: oauth.Client,
        as: AuthorizationServer,
        codeChallengeMethod: string,
        nonce: string
    ) {
        const invalid = !isValid(token)
        const doRefresh = invalid && isRefreshable(token)
        const restartAuthentication = async () => {
            clearAuthData()
            return await startAuthentication(authServerUrl, client, as, codeChallengeMethod)
        }
        if (invalid && !doRefresh) {
            return await restartAuthentication()
        }
        authenticationData.current = token.response
        authenticationValidity.refreshableUntil = token.validity.refreshableUntil
        authenticationValidity.validUntil = token.validity.validUntil
        const tokenFunction = createTokenObj(as, client, authenticationData, authenticationValidity, restoreAuthSearch(), nonce)
        if (doRefresh) {
            console.debug("Token not longer valid, refreshing..")
            try {
                await tokenFunction.refresh()
            } catch (e) {
                console.warn("Refreshing token failed, restart authentication procedure...", e)
                return await restartAuthentication()
            }
        }
        setTokenFunction(tokenFunction)
    }

    useEffect(() => {
        if (!skip && args.authenticationServerUrl) {
            const authServerUrl = args.authenticationServerUrl ?? ""
            const func = async () => {
                const client = {
                    client_id: args.clientId,
                    ...(args.clientSecret === undefined ? { token_endpoint_auth_method: "none" } : { client_secret: args.clientSecret })
                } as oauth.Client
                const codeChallengeMethod = args.codeChallengeMethod ?? "S256"
                const as = await getAuthenticationServer(authServerUrl, codeChallengeMethod, args.expectedIssuerUrl)
                const token = restoreAuthData()
                const [, , , , nonce] = restoreAuthInitializationData()
                if (token) {
                    return await foundExistingToken(token, authServerUrl, client, as, codeChallengeMethod, nonce)
                } else if (isRedirectedFromAuthServer()) {
                    await redirectedFromAuthServer(as, client)
                } else {
                    await startAuthentication(authServerUrl, client, as, codeChallengeMethod)
                }
            }
            // no await, because not within an async method here
            func().catch(e => {
                throw e
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return { tokenFunction, authenticationError, skip }
}

const TEN_SECONDS = 10000
const THIRTY_SECONDS = 3 * TEN_SECONDS

const isValid = (token: IAuthData) => {
    return Date.now() < token.validity.validUntil
}
const isRefreshable = (token: IAuthData) => {
    return Date.now() < token.validity.refreshableUntil
}

const createTokenObj = (
    as: AuthorizationServer,
    client: oauth.Client,
    ref: MutableRefObject<IAuthResponse | undefined>,
    authenticationValidity: IAuthValidity,
    search: string,
    nonce: string
) => {
    const searchParams = new URLSearchParams(search)

    const callout = (func: () => void, time?: number) => {
        refreshCallout(ref, func, time ?? Math.max(0, authenticationValidity.validUntil - Date.now() - THIRTY_SECONDS))
    }
    const refreshFunction = async () => {
        try {
            await refreshToken(as, client, ref, authenticationValidity, searchParams, nonce)
            callout(refreshFunction)
        } catch (e) {
            console.warn("Refreshing token failed: " + e, e)
            const now = Date.now()
            if (authenticationValidity.refreshableUntil > now + TEN_SECONDS + 500) {
                console.debug("Retry possible, retrying to refresh in 10 seconds...")
                authenticationValidity.needsRefresh = now >= authenticationValidity.validUntil
                callout(refreshFunction, TEN_SECONDS)
            } else {
                throw e
            }
        }
    }
    callout(refreshFunction)
    const checkTokenValid = (now: number) => {
        if (now > authenticationValidity.validUntil) {
            if (authenticationValidity.refreshableUntil > now) {
                throw new Error("Lost connection to authentication server, please wait a moment and retry the operation!")
            }
            throw new Error("Authentication has become invalid (maybe the connection to the authentication server was lost), try reloading the page")
        }
    }

    return {
        accessToken: () => {
            checkTokenValid(Date.now())
            return ref.current?.access_token
        },
        idToken: () => {
            checkTokenValid(Date.now())
            return ref.current?.id_token
        },
        refresh: refreshFunction
    } as IRefreshableOpenIdTokenFunction
}

const asMillis = (seconds?: number) => {
    return 1000 * (seconds ?? 0)
}

const refreshCallout = (ref: MutableRefObject<IAuthResponse | undefined>, func: () => void, time: number) => {
    if (time > 0) {
        setTimeout(func, time)
    }
}

const refreshToken = async (
    as: AuthorizationServer,
    client: oauth.Client,
    ref: MutableRefObject<IAuthResponse | undefined>,
    authenticationValidity: IAuthValidity,
    search: URLSearchParams,
    nonce: string
) => {
    if (ref.current?.refresh_token) {
        const response = await refreshAccessToken(as, client, ref.current?.refresh_token, search, nonce)
        updateStoredAuthenticationData(response, ref, authenticationValidity)
    }
}

const updateStoredAuthenticationData = (
    response: OpenIDTokenEndpointResponse,
    ref: MutableRefObject<IAuthResponse | undefined>,
    authenticationValidity: IAuthValidity
) => {
    ref.current = response
    if (ref.current) {
        authenticationValidity.refreshableUntil = Date.now() + Math.max(0, asMillis(ref.current?.refresh_expires_in ?? 0) - 10)
        authenticationValidity.validUntil = Date.now() + asMillis(ref.current?.expires_in)
        authenticationValidity.needsRefresh = false
        storeAuthToken(response, authenticationValidity)
    }
    console.debug("finished refreshing token and validity...", ref, authenticationValidity)
}
