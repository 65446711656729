import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CatalogService } from "@encoway/c-services-js-client"
import { AbbLineItem } from "../sales/sales.types"

export interface CatalogState {
    catalogService?: CatalogService
    selectedLineItem?: AbbLineItem
}

const initialState: CatalogState = {
    catalogService: undefined,
    selectedLineItem: undefined
}

const CatalogSlice = createSlice({
    name: "catalogSlice",
    initialState,
    reducers: {
        setCatalogService: (state, action: PayloadAction<CatalogService>) => {
            state.catalogService = action.payload
        },
        setSelectedLineItem: (state, action) => {
            state.selectedLineItem = action.payload
        }
    }
})

export default CatalogSlice
