export const NewsWidgetStyles = {
    sliderStyle: {
        margin: "16px",
        paddingBottom: "48px",
        "& .slick-list": {
            marginLeft: "-16px"
        },
        "& .slick-track": {
            marginLeft: 0,
            height: "400px",
            overflow: "hidden"
        }
    }
}
