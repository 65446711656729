import { CSSProperties, ReactElement, ReactNode } from "react"
import { Box, Stack, StackProps, Tab as MuiTab, Tabs as MuiTabs } from "@mui/material"
import { SxProps } from "@mui/system"
import TabLabel from "./tabLabel/TabLabel"

export interface Tab {
    cypressId: string
    label: string
    disabled?: boolean
    icon?: ReactElement
    children?: ReactNode
}

export interface TabsStyles {
    tabs?: SxProps
    tabsIndicator?: CSSProperties
    tab?: (tab: number, selectedTab: number) => SxProps
    tabLabel?: (tab: number, selectedTab: number) => SxProps
    contentBox?: (selectedTab: number) => SxProps
}

export interface TabsProps {
    tabs: Tab[]
    selectedTab: number
    setSelectedTab: (tab: number) => void
    tabsStyles?: TabsStyles
    children?: ReactNode
    stackProps?: StackProps
}

export default function Tabs({ tabs, selectedTab = 0, setSelectedTab, tabsStyles, stackProps, children }: Readonly<TabsProps>) {
    return (
        <Stack {...stackProps}>
            <MuiTabs
                value={selectedTab}
                TabIndicatorProps={{ style: { transition: "none", ...tabsStyles?.tabsIndicator } }}
                sx={tabsStyles?.tabs}
                indicatorColor="secondary"
                data-cy="MuiTabs"
            >
                {tabs.map((tab, index) => (
                    <MuiTab
                        data-cy={`MuiTabs.MuiTab.${tab.cypressId}`}
                        key={tab.label}
                        disabled={tab.disabled}
                        disableRipple
                        label={<TabLabel tab={tab} sx={tabsStyles?.tabLabel ? tabsStyles?.tabLabel(index, selectedTab!) : undefined} />}
                        sx={tabsStyles?.tab ? tabsStyles.tab(index, selectedTab!) : undefined}
                        onClick={() => setSelectedTab?.(index)}
                    />
                ))}
                <MuiTab
                    data-cy="MuiTabs.MuiTab"
                    label={<TabLabel sx={tabsStyles?.tabLabel?.(tabs.length, selectedTab)} />}
                    sx={tabsStyles?.tab?.(tabs.length, selectedTab)}
                    disabled
                />
            </MuiTabs>
            <Box sx={tabsStyles?.contentBox?.(selectedTab)}>{children || tabs[selectedTab].children}</Box>
        </Stack>
    )
}
