import Button, { ButtonProps } from "@mui/material/Button"
import useBlocker, { ShouldBlock } from "../../../router/hooks/useBlocker"
import { useLayoutEffect, useState } from "react"
import SaveButtonDialog from "./SaveButtonDialog"

interface SaveButtonProps extends Omit<ButtonProps, "children" | "onClick"> {
    label: string
    onClick: () => Promise<void> | void
    shouldBlockNavigation: ShouldBlock
    shouldBlockUnload: boolean
}

export default function SaveButton({ label, onClick, shouldBlockNavigation, shouldBlockUnload, ...props }: Readonly<SaveButtonProps>) {
    const [saved, setSaved] = useState(false)
    const blocker = useBlocker(shouldBlockNavigation, shouldBlockUnload)

    useLayoutEffect(() => {
        if (saved && blocker.proceed) {
            setSaved(false)
            blocker.proceed()
        }
    }, [blocker, saved])

    const save = async () => {
        await onClick()
        setSaved(true)
    }

    return (
        <>
            <Button data-cy="SaveButton" variant="contained" onClick={save} {...props}>
                {label}
            </Button>

            <SaveButtonDialog open={blocker.state === "blocked"} onSave={save} onConfirm={blocker.proceed!} onCancel={blocker.reset!} />
        </>
    )
}
