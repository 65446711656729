import React, { useEffect, useState } from "react"
import { ConfigurationService } from "@encoway/c-services-js-client"
import { useParams } from "react-router-dom"
import { Http } from "@encoway/sales-showroom-auth"
import { Settings } from "../../../../settings"
import { ConAnApp } from "../ConAnApp/ConAnApp"
import AbbOverlaySpinner from "../../../projectPage/components/projectConfiguration/components/cui/components/AbbSpinner/AbbOverlaySpinner"
import { useToken } from "../../../rootPage/components/abbOpenIdConnect/AbbOpenIdConnect"

export const ConanBase = () => {
    const [configurationService, setConfigurationService] = useState<ConfigurationService>()
    const params = useParams()
    const tokenFunction = useToken()
    const configurationId = params.configurationId
    useEffect(() => {
        if (configurationId) {
            const http = Http.Bearer(tokenFunction).withCredentialsInclude()
            ConfigurationService.create(http, Settings.configuration.baseUrl, { configurationId }, Settings.locale).then(setConfigurationService)
        }
    }, [configurationId, tokenFunction])
    return configurationService ? <ConAnApp configurationService={configurationService} /> : <AbbOverlaySpinner />
}
