import {
    AbbLineItem,
    AbbLineItemProperties,
    AbbLineItemWithParent,
    AbbSalesDocumentEntity,
    ConfigurationInterfaceDecision,
    ConfigurationInterfaceDecisions,
    ConfigurationInterfaceDecisionsDifference
} from "../sales.types"
import { DragAndDropHoverPosition } from "../../../utils/getDragAndDropHoverPosition"
import { isFooter } from "../../../pages/projectPage/components/projectComposition/lineItemsDataGrid/components/row/rowUtils"
import { isNil } from "lodash"
import { ValueType } from "@encoway/c-services-js-client"

export default class LineItemsUtils {
    static getConfigurationInterfaceDecisionsDifferences(
        targetLineItem: AbbLineItem,
        sourceLineItem: AbbLineItem
    ): ConfigurationInterfaceDecisionsDifference[] {
        const targetDecisionsJson = targetLineItem.properties.CONFIGURATION_INTERFACE_DECISIONS
        const sourceDecisionsJson = sourceLineItem.properties.CONFIGURATION_INTERFACE_DECISIONS
        try {
            const targetDecisions = targetDecisionsJson ? JSON.parse(targetDecisionsJson) : undefined
            const sourceDecisions = sourceDecisionsJson ? JSON.parse(sourceDecisionsJson) : undefined
            return targetDecisions && sourceDecisions ? this.getDifferences(targetDecisions, sourceDecisions) : []
        } catch {
            return []
        }
    }

    static getConfigurationInterfaceDecisions(lineItem: AbbLineItem) {
        const decisionsJson = lineItem.properties.CONFIGURATION_INTERFACE_DECISIONS
        try {
            if (decisionsJson) {
                return JSON.parse(decisionsJson)?.decisions || []
            } else {
                return []
            }
        } catch {
            return []
        }
    }

    private static getDifferences(
        targetDecisions: ConfigurationInterfaceDecisions,
        sourceDecisions: ConfigurationInterfaceDecisions
    ): ConfigurationInterfaceDecisionsDifference[] {
        return targetDecisions.decisions.reduce((result: ConfigurationInterfaceDecisionsDifference[], targetDecision) => {
            const sourceDecision = sourceDecisions.decisions.find(decision => decision.parameterName === targetDecision.parameterName)
            return sourceDecision && this.getDecisionValue(sourceDecision) !== this.getDecisionValue(targetDecision)
                ? result.concat({
                      parameterName: targetDecision.parameterName,
                      targetParameterValue: targetDecision.parameterValue,
                      sourceParameterValue: sourceDecision.parameterValue
                  })
                : result
        }, [])
    }

    private static getDecisionValue(decision: ConfigurationInterfaceDecision) {
        switch (decision.parameterType) {
            case ValueType.Double:
            case ValueType.RangeDouble:
                return parseFloat(decision.parameterValue)
            case ValueType.Integer:
            case ValueType.RangeInteger:
                return parseInt(decision.parameterValue)
            default:
                return decision.parameterValue
        }
    }

    static getConfigurationInterfaceTargetLineItemIds(lineItem: AbbLineItem) {
        const propertyValue = lineItem.properties.CONFIGURATION_INTERFACE_TARGET_LINE_ITEM_IDS
        return propertyValue ? propertyValue.split(",") : undefined
    }

    static isDraggableLineItem(lineItem: AbbLineItem) {
        return lineItem.permission.includes("UPDATE") && !lineItem.properties.isSubarticle && !this.isProjectOptions(lineItem)
    }

    static isEditableLineItemProperty(lineItem: AbbLineItem, property: keyof AbbLineItemProperties) {
        return lineItem.permission.includes("UPDATE") && !!lineItem.propertyPermissions[property]?.includes("UPDATE")
    }

    static isFolder(lineItem: AbbLineItem) {
        return !lineItem.properties.isArticle
    }

    static isLineupFolder(lineItem: AbbLineItem) {
        return lineItem.properties.FOLDER_TYPE === "LINEUP"
    }

    static isProjectOptions(lineItem: AbbLineItem) {
        return lineItem.properties.ARTICLE_TYPE === "PROJECT_OPTIONS"
    }

    static getSubName(lineItem: AbbLineItem) {
        if (this.isFolder(lineItem) && !isNil(lineItem.properties.label)) {
            return lineItem.properties.label
        } else if (!isNil(lineItem.properties.CONFIGURATION_NAME) && lineItem.properties.CONFIGURATION_NAME !== lineItem.properties.label) {
            return lineItem.properties.CONFIGURATION_NAME
        } else {
            return undefined
        }
    }

    static getLineItemLabel(lineItem: AbbLineItem): string {
        if (lineItem.properties.TYPE_CODE_PREFERRED_NAME) {
            return lineItem.properties.TYPE_CODE_PREFERRED_NAME as string
        } else if (lineItem.properties.BASE_TYPE_CODE) {
            // this "BASE_TYPE_CODE" case might be removed in future releases in 2025
            return lineItem.properties.BASE_TYPE_CODE
        } else if (lineItem.properties.ARTICLE_TYPE === "UNIT_OPTIONS") {
            return lineItem.properties.label as string
        } else {
            return lineItem.properties.label ?? ""
        }
    }

    static isArticle(lineItem: AbbLineItem) {
        return !!lineItem.properties.isArticle
    }

    static isConfigurable(lineItem: AbbLineItem) {
        return !!lineItem.properties.configurable
    }

    static isCommonOptionsFolder(lineItem: AbbLineItem) {
        return lineItem.properties.FOLDER_TYPE === "COMMON_OPTIONS"
    }

    static canDropLineItem(
        dragElement: AbbLineItemWithParent,
        dropElement: AbbLineItemWithParent,
        salesDocument: AbbSalesDocumentEntity,
        hoverPosition: DragAndDropHoverPosition
    ) {
        if (!hoverPosition || isFooter(dropElement.lineItem)) {
            return false
        } else if (dragElement.lineItem.properties.FOLDER_TYPE === "LINEUP") {
            return this.canDropLineup(dropElement, salesDocument, hoverPosition)
        }

        const parent = hoverPosition === "center" ? dropElement : dropElement.parent
        const lineup = this.getLineup(dragElement)
        const isLineupCompatible = dragElement.lineItem.properties.LINEUP_COMPATIBLE || false
        if (parent) {
            return (
                parent.lineItem.permission.includes("UPDATE") &&
                this.isFolder(parent.lineItem) &&
                (parent.lineItem.properties.FOLDER_TYPE !== "LINEUP" || isLineupCompatible)
            )
        } else {
            return salesDocument.permission.includes("UPDATE") && !lineup
        }
    }

    // lineups may not be moved into folders
    private static canDropLineup(dropElement: AbbLineItemWithParent, salesDocument: AbbSalesDocumentEntity, hoverPosition: DragAndDropHoverPosition) {
        return (hoverPosition === "top" || hoverPosition === "bottom") && !dropElement.parent && salesDocument.permission.includes("UPDATE")
    }

    private static getLineup(lineItem: AbbLineItemWithParent): AbbLineItemWithParent | undefined {
        if (!lineItem.parent) {
            return undefined
        } else if (lineItem.parent.lineItem.properties.FOLDER_TYPE === "LINEUP") {
            return lineItem.parent
        } else {
            return this.getLineup(lineItem.parent)
        }
    }
}
