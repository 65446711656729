import { SalesDocumentsPaging } from "../../features/sales/sales.constants"
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid"
import { AbbSalesDocumentProperty } from "../../features/sales/sales.types"

export const SearchAllProjectColumnsDropdownMenuItemValue = "ALL"

export const ProjectSearchProperties: AbbSalesDocumentProperty[] = [
    "quote_id",
    "drivesmart_id",
    "project_name",
    "salesforce_opportunity_number",
    "customer",
    "quote_created_by",
    "quote_modified_by",
    "quote_status"
]

export const initialGridSortModel: GridSortModel = [
    {
        field: SalesDocumentsPaging.DEFAULT_SORT_FIELD,
        sort: SalesDocumentsPaging.DEFAULT_SORT_DIRECTION
    } as const
]

export const initialGridPaginationModel: GridPaginationModel = {
    page: SalesDocumentsPaging.INITIAL_PAGE,
    pageSize: SalesDocumentsPaging.INITIAL_PAGE_SIZE
} as const
