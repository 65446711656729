import { Box, Stack, Typography } from "@mui/material"
import Image from "../../../../components/image/Image"

export interface CatalogEntityInfoDrawerContentProps {
    name?: string
    imageUrl?: string
    shortText?: string
    longText?: string
}

export function CatalogEntityInfoDrawerContent({ name, imageUrl, shortText, longText }: Readonly<CatalogEntityInfoDrawerContentProps>) {
    return (
        <Stack spacing={3} padding={3}>
            {name && <Typography variant="h3">{name}</Typography>}
            {imageUrl && <Image src={imageUrl + "&resolution=medium"} width={400} />}
            {shortText && <Typography fontWeight="bold">{shortText}</Typography>}
            {longText && <Box dangerouslySetInnerHTML={{ __html: longText }} />}
        </Stack>
    )
}
