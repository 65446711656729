import { Section, SectionProps } from "@encoway/cui-configurator-components"
import { AbbHorizontalSectionStyles, AbbSectionStyles } from "./AbbSection.styles"
import { BLOCKED_LAYOUT_SECTION, ContentBlockIds } from "../../../../../../../../features/catalog/catalog.constants"
import { ContainerTO } from "@encoway/c-services-js-client"

const shouldDisplayLabel = (container: ContainerTO) => !BLOCKED_LAYOUT_SECTION.includes(container.name)

export function AbbSection(props: SectionProps) {
    return (
        <Section
            {...props}
            shouldDisplayLabel={shouldDisplayLabel}
            styles={props.data?.name === ContentBlockIds.HorizontalLayout ? AbbHorizontalSectionStyles : AbbSectionStyles}
            prefix=""
        />
    )
}
