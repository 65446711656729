import React from "react"
import { Stack, Tooltip } from "@mui/material"
import { DataImportAnimationStyles } from "./DataImportAnimation.styles"

import "./DataImportAnimation.css"
import { CloudDownload } from "@mui/icons-material"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"

export const DataImportAnimation = () => {
    return (
        <Tooltip title={L10n.format(TranslationKeys.components.header.importInProgress)}>
            <Stack style={DataImportAnimationStyles}>
                <CloudDownload />
            </Stack>
        </Tooltip>
    )
}
