import { Characteristics } from "../../../catalog/catalog.constants"
import TranslationKeys from "../../../translations/TranslationKeys"
import { translateIfDefined } from "../../../translations/translations.utils"

export const getProductCategoryInfoMessage = (productCategory?: string) => {
    switch (productCategory) {
        case Characteristics.ProductCategory.possibleValues.DC:
            return translateIfDefined(TranslationKeys.lineItem.newArticle.dialog.infoMessageDC)
        case Characteristics.ProductCategory.possibleValues.MV:
            return translateIfDefined(TranslationKeys.lineItem.newArticle.dialog.infoMessageMV)
        case Characteristics.ProductCategory.possibleValues.LV:
            return translateIfDefined(TranslationKeys.lineItem.newArticle.dialog.infoMessageLV)
        default:
            return translateIfDefined(TranslationKeys.lineItem.newArticle.dialog.infoMessageNoProductCategorySet)
    }
}
