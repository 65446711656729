import { Stack } from "@mui/material"
import { ProjectForm } from "./projectForm/ProjectForm"
import { useProjectDetailsForms } from "./hooks/useProjectDetailsForms"

export default function ProjectDetails() {
    const { forms } = useProjectDetailsForms()
    return (
        <Stack spacing={3} width="100%">
            {forms?.map(form => <ProjectForm key={form.id} formDefinition={form} />)}
        </Stack>
    )
}
