import { useSearchParams } from "react-router-dom"
import SalesApi from "../../../../../features/sales/sales.api"
import { ProjectConfigurationSearchParams } from "../ProjectConfiguration.constants"
import { useMemo } from "react"
import { getLineItem } from "../ProjectConfiguration.utils"

export function useProjectConfigurationLineItem() {
    const [searchParams] = useSearchParams()
    const projectConfigurationQuery = SalesApi.useProjectConfigurationQuery()
    const lineItemId = searchParams.get(ProjectConfigurationSearchParams.LINE_ITEM_ID)
    return useMemo(() => {
        return lineItemId && projectConfigurationQuery.currentData ? getLineItem(lineItemId, projectConfigurationQuery.currentData)?.lineItem : undefined
    }, [lineItemId, projectConfigurationQuery.currentData])
}
