import { useCallback, useMemo } from "react"
import { Alert, Stack } from "@mui/material"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../translations/TranslationKeys"
import MenuButton from "../../../../components/buttons/menuButton/MenuButton"
import SalesApi from "../../sales.api"
import { useAppSelector } from "../../../../store/store"
import { SalesDocumentStatusValues } from "../../sales.constants"
import SalesUtils from "../../sales.utils"
import useDialog from "../../../../components/dialog/useDialog"
import Dialog from "../../../../components/dialog/Dialog"
import { hasIncompleteConfigurations, hasMissingRequiredProperties } from "./ChangeSalesDocumentStatusButton.utils"

export function ChangeSalesDocumentStatusButton() {
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)
    const [setSalesDocumentStatus, { data: response }] = SalesApi.useSetSalesDocumentStatusMutation()

    const status = SalesApi.useSalesDocumentQuery(undefined, {
        selectFromResult: ({ data }) => ({ status: data?.properties.quote_status })
    }).status

    const dialog = useDialog()

    const updateStatus = useCallback(
        (value: string) => {
            setSalesDocumentStatus(value).unwrap().then(dialog.open).catch()
        },
        [dialog, setSalesDocumentStatus]
    )

    const statusValues = useMemo(() => {
        return Object.values(SalesDocumentStatusValues).map(value => ({
            value,
            text: SalesUtils.salesDocument.getStatusTranslation(value)!,
            cypressId: value
        }))
    }, [])

    return (
        <>
            <MenuButton
                variant="contained"
                text={L10n.format(TranslationKeys.project.actions.changeStatus)}
                disabled={readOnly}
                color="secondary"
                value={status}
                items={statusValues}
                onSelectItem={updateStatus}
            />
            {response && (
                <Dialog title={L10n.format(TranslationKeys.project.changeStatusDialog.title)} onClose={dialog.close} open={dialog.isOpen}>
                    <Stack spacing={2}>
                        {hasIncompleteConfigurations(response) ? (
                            <Alert severity="error">{L10n.format(TranslationKeys.project.changeStatusDialog.incompleteConfigurations)}</Alert>
                        ) : (
                            <Alert severity="success">{L10n.format(TranslationKeys.project.changeStatusDialog.noIncompleteConfigurations)}</Alert>
                        )}
                        {hasMissingRequiredProperties(response) ? (
                            <Alert severity="error">{L10n.format(TranslationKeys.project.changeStatusDialog.missingRequiredFields)}</Alert>
                        ) : (
                            <Alert severity="success">{L10n.format(TranslationKeys.project.changeStatusDialog.noMissingRequiredFields)}</Alert>
                        )}
                    </Stack>
                </Dialog>
            )}
        </>
    )
}
