import { ParameterTO } from "@encoway/c-services-js-client"
import { MessageBar } from "@fluentui/react"
import { L10n } from "@encoway/l10n"
import CuiUtils from "../../utils/CuiUtils"
import { Notifications } from "./Notification.constants"
import { merge } from "lodash"
import NotificationStyles from "./Notification.styles"

interface NotificationProps {
    data: ParameterTO
}

export default function Notification(props: NotificationProps) {
    const notificationLevel = CuiUtils.readViewportPropertyValue(props.data.viewPortProperties!.NOTIFICATION_LEVEL as string)
    const notification = notificationLevel ? Notifications[notificationLevel] : undefined
    const translatedValue = props.data?.selectedValues?.at(0)?.translatedValue
    const messageBarStyles = merge(NotificationStyles, { root: { borderLeftColor: notification?.color }, icon: { color: notification?.color } })
    return translatedValue && notification ? (
        <div style={{ margin: "5px 0" }}>
            <h3>{L10n.format(notification.translationKey)}</h3>
            <MessageBar messageBarType={notification.type} styles={messageBarStyles}>
                {translatedValue}
            </MessageBar>
        </div>
    ) : null
}
