import { useEffect } from "react"
import { DropdownProps } from "@encoway/cui-configurator-components"
import { useAppDispatch } from "../../../../../../../../store/store"
import { setDefaultLayout } from "./AbbDefaultLayout.utils"

export function AbbDefaultLayout(props: DropdownProps<any>) {
    const dispatch = useAppDispatch()
    const layout = props.data.selectedValues?.at(0)?.value || ""

    useEffect(() => {
        setDefaultLayout(layout, dispatch)
    }, [dispatch, layout])

    return null
}
