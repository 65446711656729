import { Product } from "@encoway/c-services-js-client"
import { useMemo } from "react"
import CatalogUtils from "../../catalog/catalog.utils"
import { Characteristics } from "../../catalog/catalog.constants"
import SalesApi from "../sales.api"

export function useSalesDocumentPropertyCharacteristics(product?: Product, sortCharacteristic?: string) {
    const characteristicsQuery = SalesApi.useSalesDocumentPropertyCharacteristicsQuery()
    return useMemo(() => {
        const characteristicIds = product
            ? CatalogUtils.getCharacteristicValues<string[]>(product, Characteristics.ProjectPropertyCharacteristics.id)
            : characteristicsQuery.data?.map(characteristic => characteristic.id)
        const filteredCharacteristics = characteristicsQuery.data?.filter(characteristic => characteristicIds?.includes(characteristic.id)) ?? []
        return sortCharacteristic ? CatalogUtils.sortByNumberCharacteristic(filteredCharacteristics, sortCharacteristic) : filteredCharacteristics
    }, [product, characteristicsQuery.data, sortCharacteristic])
}
