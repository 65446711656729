import theme from "../../../../../App.theme"

export const FIRST_LEVEL_ITEM_CLASS_NAME = "first_level_item"
export const CONFIGURABLE_ARTICLE_CLASS_NAME = "configurable_article"

const LineItemsDataGridStyles = {
    minHeight: 140,
    "& .MuiDataGrid-overlayWrapperInner": {
        height: "40px !important"
    },
    "& .MuiDataGrid-virtualScroller .MuiDataGrid-filler": {
        height: "20px !important"
    },
    "& .MuiDataGrid-pinnedRows--bottom": {
        backgroundColor: theme.palette.background.default + " !important",
        borderTopWidth: "0px !important",
        borderBottomWidth: "0px !important",
        boxShadow: "black 10px",
        width: "var(--DataGrid-rowWidth)"
    },
    [`.${FIRST_LEVEL_ITEM_CLASS_NAME}`]: {
        backgroundColor: theme.palette.grey["300"] + " !important"
    },
    [`.${CONFIGURABLE_ARTICLE_CLASS_NAME}`]: {
        backgroundColor: `${theme.palette.grey["200"]} !important`
    },
    "& .MuiDataGrid-row:hover": {
        cursor: "default"
    }
}
export default LineItemsDataGridStyles
