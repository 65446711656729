import TextField, { TextFieldProps } from "@mui/material/TextField"
import { InputLabel, InputLabelProps } from "./Input.Label"
import { merge } from "lodash"

export type InputProps = TextFieldProps & Omit<InputLabelProps, "onChange">

export default function Input({ label, info, renderInfoInDrawer, ...props }: Readonly<InputProps>) {
    const id = label?.replace(" ", "_") + "_input"
    return (
        <TextField
            data-cy={`TextField:${id}`}
            label={<InputLabel label={label} info={info} renderInfoInDrawer={renderInfoInDrawer} />}
            id={id}
            autoComplete="on"
            {...props}
            inputProps={merge({ "data-cy": `Input.${id}` }, props.inputProps)}
        />
    )
}
