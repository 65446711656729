import { useCallback, useEffect } from "react"
import { generatePath, Outlet } from "react-router-dom"
import { L10n } from "@encoway/l10n"
import { Badge, Stack } from "@mui/material"
import { CheckCircle } from "@mui/icons-material"
import TranslationKeys from "../../features/translations/TranslationKeys"
import useParams from "../../router/hooks/useParams"
import SaveButton from "../../components/buttons/saveButton/SaveButton"
import ProjectPageStyles from "./ProjectPage.styles"
import Icon from "../../components/icons/Icon"
import { useAppSelector } from "../../store/store"
import { ShouldBlock } from "../../router/hooks/useBlocker"
import RouterPaths from "../../router/constants/RouterPaths"
import SalesApi from "../../features/sales/sales.api"
import { ContentBlockIds, ProductGroupIds } from "../../features/catalog/catalog.constants"
import { MainContainer } from "../../components/mainContainer/MainContainer"
import Breadcrumbs, { Breadcrumb } from "../../components/breadcrumbs/Breadcrumbs"
import { useSaveConfiguration } from "./components/projectConfiguration/hooks/useSaveConfiguration"
import { ProjectPageInfoIcon } from "./ProjectPage.InfoIcon"
import { CatalogApi } from "../../features/catalog/catalog.api"
import { RouterTab, RouterTabs } from "../../router/components/routerTabs/RouterTabs"
import { useProjectDetailsForms } from "./components/projectDetails/hooks/useProjectDetailsForms"
import { ChangeSalesDocumentStatusButton } from "../../features/sales/components/changeSalesDocumentStatusButton/ChangeSalesDocumentStatusButton"

export default function ProjectPage() {
    const salesDocumentIsSaved = useAppSelector(state => state.sales.salesDocumentIsSaved)
    const configurationIsSaved = useAppSelector(state => state.sales.configurationIsSaved)
    const readOnly = useAppSelector(state => state.sales.salesDocumentReadOnly)

    const projectInfoTooltip = CatalogApi.useProductQuery(ContentBlockIds.ProjectInfoTooltip).data?.product

    const [openSalesDocument, openSalesDocumentResult] = SalesApi.useOpenSalesDocumentMutation()
    const [closeSalesDocument] = SalesApi.useCloseSalesDocumentMutation()
    const [saveSalesDocument] = SalesApi.useSaveSalesDocumentMutation()

    const salesDocumentData = SalesApi.useSalesDocumentQuery(undefined, {
        skip: !openSalesDocumentResult.isSuccess,
        selectFromResult: ({ data }) => ({
            salesDocumentId: data?.salesDocumentId,
            projectName: data?.properties.project_name,
            quoteId: data?.properties.quote_id,
            productCategory: data?.properties.PRODUCT_CATEGORY
        })
    })
    const salesDocumentId = salesDocumentData?.salesDocumentId

    const { numberOfInvalidInputs } = useProjectDetailsForms()
    const saveConfiguration = useSaveConfiguration()
    const params = useParams()

    useEffect(() => {
        openSalesDocument(params.salesDocumentId!)
        return () => {
            closeSalesDocument()
        }
    }, [openSalesDocument, closeSalesDocument, params.salesDocumentId])

    const shouldBlockNavigation: ShouldBlock = useCallback(
        args => Boolean(salesDocumentId && !salesDocumentIsSaved && !args.nextLocation.pathname.includes(salesDocumentId)),
        [salesDocumentId, salesDocumentIsSaved]
    )

    if (!salesDocumentId) return null

    const save = async () => {
        if (!configurationIsSaved) {
            await saveConfiguration()
        }
        await saveSalesDocument()
    }

    const tabs: RouterTab[] = [
        {
            cypressId: "tab.details",
            label: L10n.format(TranslationKeys.pages.project.tabs.details),
            baseRoute: generatePath(RouterPaths.PROJECT_DETAILS, { salesDocumentId }),
            icon:
                numberOfInvalidInputs > 0 ? (
                    <Badge badgeContent={numberOfInvalidInputs} color="error" sx={ProjectPageStyles.badge} />
                ) : (
                    <Icon component={CheckCircle} color="success" />
                )
        },
        {
            cypressId: "tab.catalog",
            label: L10n.format(TranslationKeys.pages.project.tabs.catalog),
            baseRoute: generatePath(RouterPaths.PROJECT_CATALOG, { salesDocumentId, productGroupIds: ProductGroupIds.Catalog }),
            navigationRoute: generatePath(RouterPaths.PROJECT_CATALOG, {
                salesDocumentId: salesDocumentId,
                productGroupIds:
                    ProductGroupIds.Catalog +
                    (salesDocumentData.productCategory ? `+${ProductGroupIds[salesDocumentData.productCategory as keyof typeof ProductGroupIds]}` : "")
            })
        },
        {
            cypressId: "tab.configuration",
            label: L10n.format(TranslationKeys.pages.project.tabs.configuration),
            baseRoute: generatePath(RouterPaths.PROJECT_CONFIGURATION, { salesDocumentId })
        },
        {
            cypressId: "tab.composition",
            label: L10n.format(TranslationKeys.pages.project.tabs.composition),
            baseRoute: generatePath(RouterPaths.PROJECT_COMPOSITION, { salesDocumentId })
        },
        {
            cypressId: "tab.reports",
            label: L10n.format(TranslationKeys.pages.project.tabs.reports),
            baseRoute: generatePath(RouterPaths.PROJECT_REPORTS, { salesDocumentId })
        }
    ]

    const breadcrumbs: Breadcrumb[] = [
        { label: L10n.format(TranslationKeys.pages.projects.pageName), route: RouterPaths.PROJECTS },
        { label: `${salesDocumentData.projectName} (${salesDocumentData.quoteId})` }
    ]

    return (
        <MainContainer sx={ProjectPageStyles.container}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Stack direction="row" spacing={1} alignItems="center" minWidth={300}>
                    <Breadcrumbs breadcrumbs={breadcrumbs} sx={ProjectPageStyles.breadcrumbs} />
                    {projectInfoTooltip && <ProjectPageInfoIcon definition={projectInfoTooltip} />}
                </Stack>
                <Stack direction="row" spacing={2} flexShrink={0}>
                    <ChangeSalesDocumentStatusButton />
                    <SaveButton
                        label={L10n.format(TranslationKeys.project.actions.save)}
                        disabled={(salesDocumentIsSaved && configurationIsSaved) || readOnly}
                        shouldBlockNavigation={shouldBlockNavigation}
                        shouldBlockUnload={!salesDocumentIsSaved}
                        onClick={save}
                    />
                </Stack>
            </Stack>

            <RouterTabs tabs={tabs} stackProps={{ flexGrow: 1, overflow: "auto" }} tabsStyles={ProjectPageStyles.tabStyles}>
                <Outlet />
            </RouterTabs>
        </MainContainer>
    )
}
