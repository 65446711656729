import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import theme from "../../App.theme"
import InfoIcon from "../../components/icons/infoIcon/InfoIcon"
import SalesApi from "../../features/sales/sales.api"
import { Characteristics } from "../../features/catalog/catalog.constants"
import { AbbSalesDocumentProperties } from "../../features/sales/sales.types"
import { Product } from "@encoway/c-services-js-client"
import { useSalesDocumentPropertyCharacteristics } from "../../features/sales/hooks/useSalesDocumentPropertyCharacteristics"

interface ProjectPageInfoIconProps {
    definition: Product
}

export function ProjectPageInfoIcon({ definition }: Readonly<ProjectPageInfoIconProps>) {
    const characteristics = useSalesDocumentPropertyCharacteristics(definition, Characteristics.ProjectInfoTooltipOrder.id)
    const salesDocumentProperties: Partial<AbbSalesDocumentProperties> = SalesApi.useSalesDocumentQuery(undefined, {
        selectFromResult: ({ data }) => {
            return characteristics.reduce((properties, characteristic) => ({ ...properties, [characteristic.id]: data?.properties[characteristic.id] }), {})
        }
    })

    return (
        <InfoIcon>
            <TableContainer>
                <Table>
                    <TableBody>
                        {characteristics.map(characteristic => (
                            <TableRow key={characteristic.id} sx={{ td: { px: 1, py: 0 } }}>
                                <TableCell>
                                    <Typography fontWeight={theme.typography.fontWeightBold} whiteSpace="nowrap" textAlign="right">
                                        {characteristic.name}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography>{salesDocumentProperties[characteristic.id] as string}</Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </InfoIcon>
    )
}
