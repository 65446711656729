import { L10n } from "@encoway/l10n"
import { DataGridProProps, GridActionsCellItem, GridRowParams } from "@mui/x-data-grid-pro"
import { ProjectsDataGridColumns } from "./ProjectsDataGridColumns"
import DataGrid from "../../../../components/dataGrid/DataGrid"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { AbbSalesDocumentEntity } from "../../../../features/sales/sales.types"
import { SalesDocumentProperties } from "../../../../features/sales/sales.constants"
import ProjectsDataGridStyles from "./ProjectsDataGrid.styles"
import AutoStoriesIcon from "@mui/icons-material/AutoStories"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import DeleteIcon from "@mui/icons-material/Delete"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import SalesApi from "../../../../features/sales/sales.api"

interface ProjectDataGridProps extends Omit<DataGridProProps, "columns"> {
    rows: AbbSalesDocumentEntity[]
    onSelectProject: (salesDocument: AbbSalesDocumentEntity, readOnly: boolean) => void
    onDeleteProject: (salesDocument: AbbSalesDocumentEntity) => void
    onCopyProject: (salesDocumentId: string) => any
}

export default function ProjectsDataGrid({ onSelectProject, onDeleteProject, onCopyProject, ...dataGridProps }: Readonly<ProjectDataGridProps>) {
    const salesDocumentPropertyCharacteristicsQuery = SalesApi.useSalesDocumentPropertyCharacteristicsQuery()
    const getActions = (params: GridRowParams<AbbSalesDocumentEntity>) => [
        <GridActionsCellItem
            key="editAction"
            icon={<ModeEditIcon />}
            label={L10n.format(TranslationKeys.project.actions.open)}
            onClick={() => onSelectProject(params.row, false)}
            disabled={!params.row.permission.includes("UPDATE")}
            showInMenu
        />,
        <GridActionsCellItem
            key="readAction"
            icon={<AutoStoriesIcon />}
            label={L10n.format(TranslationKeys.project.actions.read)}
            onClick={() => onSelectProject(params.row, true)}
            showInMenu
        />,
        <GridActionsCellItem
            key="copyAction"
            icon={<ContentCopyIcon />}
            label={L10n.format(TranslationKeys.project.actions.duplicate)}
            onClick={() => onCopyProject(params.row.salesDocumentId)}
            disabled={!params.row.permission.includes("UPDATE")}
            showInMenu
        />,
        <GridActionsCellItem
            key="deleteAction"
            icon={<DeleteIcon />}
            label={L10n.format(TranslationKeys.project.actions.delete)}
            onClick={() => onDeleteProject(params.row)}
            disabled={!params.row.permission.includes("DELETE")}
            showInMenu
        />
    ]

    return !salesDocumentPropertyCharacteristicsQuery.isSuccess ? null : (
        <DataGrid
            getRowId={params => params.salesDocumentId}
            columns={ProjectsDataGridColumns(salesDocumentPropertyCharacteristicsQuery.data, getActions)}
            filterMode="server"
            paginationMode="server"
            sx={ProjectsDataGridStyles}
            sortingMode="server"
            disableRowSelectionOnClick
            onRowClick={params => onSelectProject(params.row, false)}
            localeText={{
                noRowsLabel: L10n.format(TranslationKeys.pages.projects.dataGrid.noProjectsFound),
                noResultsOverlayLabel: L10n.format(TranslationKeys.pages.projects.dataGrid.noProjectResults)
            }}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        [SalesDocumentProperties.CREATED_AT]: false,
                        [SalesDocumentProperties.MODIFIED_BY]: false,
                        [SalesDocumentProperties.DRIVESMART_ID]: false
                    }
                }
            }}
            {...dataGridProps}
        />
    )
}
