import { AuthorizationServer, Client, OpenIDTokenEndpointResponse } from "oauth4webapi"
import * as oauth from "oauth4webapi"

const AUTH_INITIALIZATION_DATA = "authInitializationData"
const AUTH_DATA = "authData"
const AUTH_SEARCH = "authSearch"
const STATE = "state"

export type OidcClientSettings = {
    realm: string
    authServerUrl: string
    clientId: string
    clientSecret?: string
    redirectUri: string
}

/**
 * Obtained from product code.
 * @param authenticationServerUrl .
 * @param codeChallengeMethod .
 * @param expectedIssuerUrl .
 */

export const getAuthenticationServer = async (authenticationServerUrl: string, codeChallengeMethod?: string, expectedIssuerUrl?: string) => {
    let authorizationServer
    try {
        const response = await oauth.discoveryRequest(new URL(authenticationServerUrl))
        authorizationServer = await oauth.processDiscoveryResponse(new URL(expectedIssuerUrl ?? authenticationServerUrl), response)
    } catch (e) {
        console.error(e)
        throw e
    }

    if (
        codeChallengeMethod &&
        "code_challenge_methods_supported" in authorizationServer &&
        authorizationServer?.code_challenge_methods_supported?.includes(codeChallengeMethod) !== true
    ) {
        throw new Error("The Code challenge '" + codeChallengeMethod + "' is not supported")
    }
    return authorizationServer
}

export const isRedirectedFromAuthServer = () => {
    const currentUrlParams = getCurrentUrl().searchParams
    const sessionState = currentUrlParams.get("session_state")
    const code = currentUrlParams.get("code")
    const cachedAuthData = sessionStorage.getItem(AUTH_INITIALIZATION_DATA)
    const state = sessionStorage.getItem(STATE)
    return sessionState != null && code != null && cachedAuthData != null && state != null
}

export const getClearUrl = () => {
    const url = getCurrentUrl()
    return url.origin + url.pathname
}

export const getCurrentUrl = () => {
    return new URL(window.location.href)
}

const getStateFromSessionStorage = () => {
    const state = sessionStorage.getItem(STATE)
    if (!state) {
        console.error("Error while validating authentication response: Missing state parameter in session storage")
        throw new Error("Mandatory state parameter is missing!")
    }
    return state
}

export const redirectToAuthorizationServer = (
    oidcClientSettings: OidcClientSettings,
    oauthClient: Client,
    as: AuthorizationServer,
    codeChallenge: string,
    codeChallengeMethod: string,
    nonce: string
) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const authorizationUrl = new URL(as.authorization_endpoint!)
    const state = oauth.generateRandomState()
    sessionStorage.setItem(STATE, state)

    authorizationUrl.searchParams.set("client_id", oauthClient.client_id)
    authorizationUrl.searchParams.set("code_challenge", codeChallenge)
    authorizationUrl.searchParams.set("code_challenge_method", codeChallengeMethod)
    authorizationUrl.searchParams.set("redirect_uri", oidcClientSettings.redirectUri)
    authorizationUrl.searchParams.set("state", state)
    authorizationUrl.searchParams.set("response_type", "code")
    authorizationUrl.searchParams.set("scope", "openid email profile")
    if (nonce) authorizationUrl.searchParams.set("nonce", nonce)

    window.location.href = authorizationUrl.href
}

export const getAccessToken = async (as: AuthorizationServer, oauthClient: Client, redirectUri: string, codeVerifier: string, nonce: string) => {
    // one eternity later, the user lands back on the redirect_uri
    const currentUrl = getCurrentUrl()
    const state = getStateFromSessionStorage()
    const params = oauth.validateAuthResponse(as, oauthClient, currentUrl, state)
    if (oauth.isOAuth2Error(params)) {
        console.error("A OAuth2 redirect error occurred", params)
        throw new Error(params.error) // Handle OAuth 2.0 redirect error
    }

    const response = await oauth.authorizationCodeGrantRequest(as, oauthClient, params, redirectUri, codeVerifier)

    const challenges: oauth.WWWAuthenticateChallenge[] | undefined = oauth.parseWwwAuthenticateChallenges(response)
    if (challenges) {
        console.error("Something went wrong while handling the code challenges...")
        for (const challenge of challenges) {
            console.error("challenge ", challenge)
        }
        throw new Error("There is an Error while handling the access_token code challenge") // Handle www-authenticate challenges as needed
    }

    const result = await oauth.processAuthorizationCodeOpenIDResponse(as, oauthClient, response, nonce)

    if (oauth.isOAuth2Error(result)) {
        console.error("Authentication failed!", result)
        throw new Error(result.error_description) // Handle OAuth 2.0 response body error
    }
    return result
}

export const refreshAccessToken = async (as: AuthorizationServer, oauthClient: Client, refreshToken: string, search: URLSearchParams, nonce: string) => {
    // one eternity later, the user lands back on the redirect_uri
    const state = getStateFromSessionStorage()
    const params = oauth.validateAuthResponse(as, oauthClient, search ?? getCurrentUrl(), state)
    if (oauth.isOAuth2Error(params)) {
        console.error("A OAuth2 redirect error occurred", params)
        throw new Error(params.error) // Handle OAuth 2.0 redirect error
    }
    const response = await oauth.refreshTokenGrantRequest(as, oauthClient, refreshToken)
    const result = await oauth.processAuthorizationCodeOpenIDResponse(as, oauthClient, response, nonce)
    if (oauth.isOAuth2Error(result)) {
        console.error("Refreshing token failed!", result)
        throw new Error(result.error) // Handle OAuth 2.0 response body error
    }
    return result
}

export const storeAuthInitializationData = (oidcClient: OidcClientSettings, codeVerifier: string, codeChallenge: string, url: string, nonce: string) => {
    sessionStorage.setItem(AUTH_INITIALIZATION_DATA, JSON.stringify({ oidcClient, url, codeVerifier, codeChallenge, nonce }))
}

export const restoreAuthInitializationData = () => {
    const jsonString = sessionStorage.getItem(AUTH_INITIALIZATION_DATA)
    if (!jsonString) {
        return []
    }
    const object = JSON.parse(jsonString)
    return [object.oidcClient, object.url, object.codeVerifier, object.codeChallenge, object.nonce]
}

export interface IAuthResponse extends OpenIDTokenEndpointResponse {
    refresh_expires_in?: number
}

export const clearAuthData = () => {
    sessionStorage.removeItem(AUTH_DATA)
    sessionStorage.removeItem(AUTH_SEARCH)
    sessionStorage.removeItem(AUTH_INITIALIZATION_DATA)
}
export const storeAuthToken = (response: IAuthResponse, validity: IAuthValidity) => {
    sessionStorage.setItem(AUTH_DATA, JSON.stringify({ response, validity }))
}
export const storeAuthSearch = (search: string) => {
    sessionStorage.setItem(AUTH_SEARCH, search)
}

export interface IAuthValidity {
    needsRefresh: boolean
    refreshableUntil: number
    validUntil: number
}
export interface IAuthData {
    response: IAuthResponse
    validity: IAuthValidity
}
export const restoreAuthData = () => {
    const token = sessionStorage.getItem(AUTH_DATA)
    return token ? (JSON.parse(token) as IAuthData) : undefined
}
export const restoreAuthSearch = () => {
    const token = sessionStorage.getItem(AUTH_SEARCH)
    return token ?? ""
}
