import { useNavigate } from "react-router"
import { useLocation } from "react-router-dom"
import Tabs, { Tab, TabsProps } from "../../../components/tabs/Tabs"

export interface RouterTab extends Tab {
    baseRoute: string
    navigationRoute?: string
}

export interface RouterTabsProps extends Omit<TabsProps, "selectedTab" | "setSelectedTab"> {
    tabs: RouterTab[]
}

export function RouterTabs({ tabs, ...tabsProps }: Readonly<RouterTabsProps>) {
    const navigate = useNavigate()
    const location = useLocation()
    const selectedTab = tabs.findIndex(tab => tab?.baseRoute && location.pathname.startsWith(tab.baseRoute))
    return (
        <Tabs
            tabs={tabs}
            selectedTab={Math.max(selectedTab, 0)}
            setSelectedTab={tab => navigate(tabs.at(tab)!.navigationRoute ?? tabs.at(tab)!.baseRoute)}
            {...tabsProps}
        />
    )
}
