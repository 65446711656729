import { OidcRoles } from "./oidc.constants"
import { UserInformation } from "./hooks/useUserInformation"

export class OidcUtils {
    public static hasEditProjectsRole(userInformation: UserInformation) {
        return userInformation.roles?.includes(OidcRoles.EDIT_PROJECTS_ROLE)
    }

    public static hasUpdateProjectLsuRole(userInformation: UserInformation) {
        return userInformation.roles?.includes(OidcRoles.UPDATE_PROJECT_LSU_ROLE)
    }

    public static getLsus(userInformation?: UserInformation) {
        return (
            userInformation?.roles
                ?.filter(role => role.startsWith(OidcRoles.USER_INFO_LSU_ROLE_PREFIX))
                .map(role => role.replace(OidcRoles.USER_INFO_LSU_ROLE_PREFIX, "")) ?? []
        )
    }

    public static hasReadTransferPricesRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.READ_TRANSFER_PRICES_ROLE)
    }

    public static hasReadMultiplierRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.READ_MULTIPLIER_ROLE)
    }

    public static hasReadListPricesRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.READ_LIST_PRICES_ROLE)
    }

    public static hasUpdateListPricesRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.UPDATE_LIST_PRICES_ROLE)
    }

    public static hasReadMfkRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.READ_MFK_ROLE)
    }

    static hasReadTransferPriceDiscountRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.READ_TRANSFERPRICES_DISCOUNT_ROLE)
    }

    static hasUpdateTransferPricesDiscountRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.UPDATE_TRANSFERPRICES_DISCOUNT_ROLE)
    }

    static hasReadCostPricesRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.READ_COST_PRICES_ROLE)
    }

    static hasUpdateCostPricesRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.UPDATE_COST_PRICES_ROLE)
    }

    static hasReadSalesPricesRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.READ_SALESPRICES_ROLE)
    }

    static hasUpdateSalesPricesMultiplierRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.UPDATE_SALESPRICE_MULTIPLIER_ROLE)
    }

    static hasUpdateSalesPricesDiscountRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.UPDATE_SALESPRICE_DISCOUNT_ROLE)
    }

    static hasUpdateRetentionTimeRole(userInformation?: UserInformation) {
        return !!userInformation?.roles?.includes(OidcRoles.UPDATE_RETENTION_TIME_ROLE)
    }
}
