import { AbbSalesDocumentProperties } from "../sales.types"
import SalesApi from "../sales.api"
import { validateSalesDocumentPropertyInputValue } from "../components/salesDocumentPropertyInput/SalesDocumentPropertyInput.utils"
import { Characteristic } from "@encoway/c-services-js-client"
import { useMemo } from "react"

export function useSalesDocumentPropertiesForm(characteristics: Characteristic[], requiredCharacteristicId: string) {
    const permissions = SalesApi.useCurrencyPermissionsQuery().data

    const salesDocumentProperties: Partial<AbbSalesDocumentProperties> = SalesApi.useSalesDocumentQuery(undefined, {
        selectFromResult: ({ data }) =>
            characteristics.reduce((properties, characteristic) => ({ ...properties, [characteristic.id]: data?.properties[characteristic.id] }), {}) ?? {}
    })

    const numberOfInvalidInputs = useMemo(() => {
        return characteristics.filter(characteristic => {
            const value = salesDocumentProperties[characteristic.id] as string | number | undefined
            return !validateSalesDocumentPropertyInputValue(characteristic, requiredCharacteristicId, value, permissions)
        }).length
    }, [characteristics, permissions, requiredCharacteristicId, salesDocumentProperties])

    return useMemo(() => ({ isValid: numberOfInvalidInputs === 0, numberOfInvalidInputs }), [numberOfInvalidInputs])
}
