import { Box, Drawer as MuiDrawer, DrawerProps } from "@mui/material"
import { ResizeBar } from "../resizeBar/ResizeBar"
import React, { useRef } from "react"
import { merge } from "lodash"
import { mergeSx } from "../../utils/mergeSx"
import { DrawerSx } from "./Drawer.styles"

export function Drawer({ children, ...props }: Readonly<DrawerProps>) {
    const defaultPaperRef = useRef<HTMLElement>(null)
    const paperRef = props.PaperProps?.ref ?? defaultPaperRef
    return (
        <MuiDrawer anchor="right" {...props} PaperProps={merge({ ref: paperRef }, props.PaperProps)} sx={mergeSx(DrawerSx, props.sx)}>
            <ResizeBar axis="x" target={paperRef} direction={1} />
            <Box>{children}</Box>
        </MuiDrawer>
    )
}
