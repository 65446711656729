import { Filter, Selection, Sort } from "@encoway/c-services-js-client"
import { Characteristics, ProductGroupIds } from "../../../../features/catalog/catalog.constants"
import { Characteristics as CharacteristicsFilter } from "@encoway/c-services-js-client/dist/esm/catalog/characteristics"
import { Settings } from "react-slick"
import React from "react"
import { NextArrow } from "./NextArrow"
import { PrevArrow } from "./PrevArrow"

const productGroupFilter = Filter.productGroupFilter().id(ProductGroupIds.News)
const characteristicsFilter = new CharacteristicsFilter().all()
const sort = new Sort().characteristic(Characteristics.Date.id).desc(true)

const calculateBreakPointForSlides = (slides: number) => {
    return 48 + slides * 282
}

const sliderSettings: Settings = {
    dots: true,
    infinite: false,
    lazyLoad: "anticipated",
    speed: 500,
    centerMode: false,
    slidesToShow: 21,
    slidesToScroll: 1,
    variableWidth: true,
    adaptiveHeight: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(slides => ({
        breakpoint: calculateBreakPointForSlides(slides),
        settings: {
            slidesToShow: slides - 1
        }
    }))
}

export const NewsWidgetConstants = {
    catalogSelection: new Selection().filter(productGroupFilter).limit(100).offset(0).characteristics(characteristicsFilter).sort(sort),
    sliderSettings: sliderSettings
}
