import { EnvironmentApi } from "../features/environment/environment.api"
import { Settings } from "../settings"
import { useMemo } from "react"
import { merge } from "lodash"
import getAppPath from "../router/utils/getAppPath"
import { useAppSelector } from "../store/store"
import { useToken } from "../pages/rootPage/components/abbOpenIdConnect/AbbOpenIdConnect"

/**
 * useSettings add dynamic settings from the docker environment to the settings, e.g. the visualization url.
 */
export const useSettings = () => {
    const publicEnvironment = EnvironmentApi.usePublicEnvironmentQuery().data
    const tokenFunction = useToken()
    const token = useAppSelector(state => state.oidc.token)
    const visualizationToken = EnvironmentApi.useEnvironmentQuery(undefined, { skip: !token }).data?.visualizationToken
    return useMemo(() => {
        return merge(Settings, {
            configuration: {
                token: tokenFunction
            },
            visualization: {
                token: visualizationToken,
                baseUrl: publicEnvironment?.visualizationUri || getAppPath() + "/public/visualization",
                settings: {
                    ui: {
                        resources: {
                            draco: {
                                decoderPath: publicEnvironment?.visualizationUri || getAppPath() + "/public/visualization"
                            }
                        }
                    }
                }
            }
        })
    }, [publicEnvironment, visualizationToken, tokenFunction])
}
