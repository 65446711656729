const ProductQuickSelectionStyles = {
    container: {
        position: "fixed",
        bottom: 0,
        left: "50%",
        right: "50%",
        transform: "translate(-50%, -50%)",
        pointerEvents: "none"
    }
}

export default ProductQuickSelectionStyles
