import { IconButton } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import React from "react"
import { PrevArrowStyles } from "./PrevArrow.styles"

export const PrevArrow = (props: any) => {
    const { style, onClick, className } = props
    const disabled = className.includes("slick-disabled")
    return disabled ? null : (
        <IconButton onClick={onClick} style={PrevArrowStyles.root(style)} disabled={disabled}>
            <ArrowBackIcon />
        </IconButton>
    )
}
