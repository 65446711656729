import { ChangeEventHandler, FormEvent, useState } from "react"
import { Stack } from "@mui/material"
import Dialog, { DialogProps } from "../../../../../components/dialog/Dialog"
import Dropdown, { DropdownMenuItem } from "../../../../../components/dropdown/Dropdown"
import Input from "../../../../../components/input/Input"
import SalesApi from "../../../../../features/sales/sales.api"
import { ErrorAlert } from "../../../../../features/error/components/errorAlert/ErrorAlert"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import { Product } from "@encoway/c-services-js-client"
import { LineupNonCompatibleProducts } from "./LineupNonCompatibleProducts"

interface AddToLineupDialogProps extends Pick<DialogProps, "onClose"> {
    onSubmit: (lineupId?: string, newLineupName?: string) => Promise<any>
    lineupNonCompatibleProducts?: Product[]
}

const ADD_TO_NEW_LINEUP = "ADD_TO_NEW_LINEUP"

export function AddToLineupDialog({ onSubmit, onClose, lineupNonCompatibleProducts = [] }: Readonly<AddToLineupDialogProps>) {
    const [lineupId, setLineupId] = useState(ADD_TO_NEW_LINEUP)
    const [lineupName, setLineupName] = useState("")
    const [error, setError] = useState<Error>()
    const lineups = SalesApi.useLineupsQuery().data ?? []

    const lineupMenuItems: DropdownMenuItem[] = lineups.map(lineup => ({ value: lineup.lineItemId, text: lineup.properties.label! }))

    const menuItems: DropdownMenuItem[] = [
        { value: ADD_TO_NEW_LINEUP, text: L10n.format(TranslationKeys.pages.project.catalog.addToLineupDialog.createNewLineupDropdownOption) },
        ...lineupMenuItems
    ]

    const updateLineupId: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
        setLineupId(event.target.value)
    }

    const onConfirm = () => {
        if (lineupId === ADD_TO_NEW_LINEUP) {
            onSubmit(undefined, lineupName)
                .then(onClose)
                .catch(e => setError(e))
        } else {
            onSubmit(lineupId)
                .then(onClose)
                .catch(e => setError(e))
        }
    }

    const submit = async (event: FormEvent) => {
        event.preventDefault()
        onConfirm()
    }

    const submitWithoutLineup = () => {
        onSubmit().then(() => onClose())
    }

    return (
        <Dialog
            title={L10n.format(TranslationKeys.pages.project.catalog.addToLineupDialog.title)}
            open={true}
            onClose={onClose}
            defaultDialogActions={{
                cancelButtonLabel: L10n.format(TranslationKeys.pages.project.catalog.addToLineupDialog.declineButtonLabel),
                confirmButtonLabel: L10n.format(TranslationKeys.pages.project.catalog.addToLineupDialog.confirmButtonLabel),
                onCancel: submitWithoutLineup,
                onConfirm: onConfirm,
                disableConfirm: lineupId === ADD_TO_NEW_LINEUP && !lineupName
            }}
        >
            <form onSubmit={submit}>
                <Stack spacing={2}>
                    <Dropdown
                        label={L10n.format(TranslationKeys.pages.project.catalog.addToLineupDialog.lineupsDropdownLabel)}
                        value={lineupId}
                        menuItems={menuItems}
                        onChange={updateLineupId}
                        cypressId=""
                        disableSorting
                        fullWidth
                    />
                    {lineupId === ADD_TO_NEW_LINEUP && (
                        <Input
                            label={L10n.format(TranslationKeys.pages.project.catalog.addToLineupDialog.newLineupInputLabel)}
                            value={lineupName}
                            onChange={event => setLineupName(event.target.value)}
                        />
                    )}
                    {lineupNonCompatibleProducts.length > 0 && <LineupNonCompatibleProducts products={lineupNonCompatibleProducts} />}
                    {error && <ErrorAlert error={error.message} />}
                </Stack>
            </form>
        </Dialog>
    )
}
