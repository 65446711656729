import { Outlet } from "react-router-dom"
import AppStyles from "../../App.styles"
import Header from "./components/header/Header"
import { Stack } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../store/store"
import ErrorDialog from "./components/errorDialog/ErrorDialog"
import ErrorSlice from "../../features/error/error.slice"
import SnackbarStack from "./components/snackbarStack/SnackbarStack"
import SnackbarSlice from "../../features/snackbar/snackbar.slice"
import Progress from "./components/progress/Progress"
import AuthorizedContent from "./components/authorizedContent/AuthorizedContent"
import { TranslationsApi } from "../../features/translations/translations.api"
import { EnvironmentApi } from "../../features/environment/environment.api"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../features/translations/TranslationKeys"
import { useEffect } from "react"
import AbbOverlaySpinner from "../projectPage/components/projectConfiguration/components/cui/components/AbbSpinner/AbbOverlaySpinner"

export default function RootPage() {
    const isBusy = useAppSelector(state => state.busy.isBusy)
    const error = useAppSelector(state => state.error.error)
    const progress = useAppSelector(state => state.progress)
    const snackbars = useAppSelector(state => state.snackbar.snackbars)
    const language = useAppSelector(state => state.translations.language)

    const { isError: isTranslationsError, isSuccess: isTranslationsSuccess } = TranslationsApi.useTranslationsQuery(language)
    const { data: environment, isError: isEnvironmentError } = EnvironmentApi.usePublicEnvironmentQuery()

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (isTranslationsSuccess) {
            document.title = L10n.format(TranslationKeys.components.header.title)
        }
    }, [isTranslationsSuccess])

    useEffect(() => {
        if (isTranslationsError) {
            const error = new Error("Please contact <a href='mailto:support.sales-tools@abb.com'>support.sales-tools@abb.com</a> with the following timestamp.")
            error.name = "Failed to load translations."
            throw error
        }
    }, [isTranslationsError])

    useEffect(() => {
        if (isEnvironmentError && isTranslationsSuccess) {
            const error = new Error(L10n.format(TranslationKeys.error.failedToLoadPublicEnvironment.title))
            error.name = L10n.format(TranslationKeys.error.failedToLoadPublicEnvironment.message)
            throw error
        }
    }, [isEnvironmentError, isTranslationsSuccess])

    return (
        <>
            {isBusy && <AbbOverlaySpinner />}
            {error && <ErrorDialog error={error} onClose={() => dispatch(ErrorSlice.actions.set(undefined))} />}
            <SnackbarStack snackbars={snackbars} onClose={id => dispatch(SnackbarSlice.actions.close(id))} />
            {progress.counter !== progress.total ? <Progress {...progress} /> : null}

            <Stack sx={AppStyles.wrapper}>
                <Header withoutTranslations={!isTranslationsSuccess} />
                {isTranslationsSuccess && environment && (
                    <AuthorizedContent
                        issuerUri={environment.issuerUri}
                        expectedIssuerUri={environment.expectedIssuerUri}
                        clientId={environment.clientId}
                        discoveryUri={environment.discoveryUri}
                    >
                        <Outlet />
                    </AuthorizedContent>
                )}
            </Stack>
        </>
    )
}
