import { useCallback } from "react"
import { ConfigurationService } from "@encoway/c-services-js-client"
import { ConanService } from "../../service/ConanService"
import { View } from "./ActionBar"
import { useToken } from "../../../rootPage/components/abbOpenIdConnect/AbbOpenIdConnect"

export const useUpload = (configurationService: ConfigurationService, view: View) => {
    const tokenFunction = useToken()
    const configurationId = configurationService.id()
    const upload = useCallback(
        async (file: File) => {
            return await ConanService.compare(configurationId, file, view, tokenFunction)
        },
        [configurationId, view, tokenFunction]
    )
    return { upload: upload }
}
