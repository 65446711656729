import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { AbbSalesDocumentEntity } from "../../../../../../features/sales/sales.types"
import { Stack } from "@mui/material"
import Icon from "../../../../../../components/icons/Icon"
import SalesUtils from "../../../../../../features/sales/sales.utils"

export function StatusCell(params: Readonly<GridRenderCellParams<AbbSalesDocumentEntity>>) {
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <Icon
                component={SalesUtils.salesDocument.getStatusIcon(params.row.properties.quote_status)}
                color={SalesUtils.salesDocument.getStatusColor(params.row.properties.quote_status)}
            />
            <span>{SalesUtils.salesDocument.getStatusTranslation(params.row.properties.quote_status)}</span>
        </Stack>
    )
}
