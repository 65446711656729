import SalesApi from "../../../../../features/sales/sales.api"
import { CatalogApi } from "../../../../../features/catalog/catalog.api"
import { PROJECT_DETAILS_SELECTION } from "../ProjectDetails.constants"
import { useMemo } from "react"
import CatalogUtils from "../../../../../features/catalog/catalog.utils"
import { Characteristics } from "../../../../../features/catalog/catalog.constants"
import { useSalesDocumentPropertyCharacteristics } from "../../../../../features/sales/hooks/useSalesDocumentPropertyCharacteristics"
import { useSalesDocumentPropertiesForm } from "../../../../../features/sales/hooks/useSalesDocumentPropertiesForm"

export function useProjectDetailsForms() {
    const salesDocumentPropertyCharacteristics = useSalesDocumentPropertyCharacteristics()

    const productCategory = SalesApi.useSalesDocumentQuery(undefined, {
        selectFromResult: ({ data }) => ({ productCategory: data?.properties.PRODUCT_CATEGORY })
    }).productCategory

    const contentBlocks = CatalogApi.useProductsQuery(PROJECT_DETAILS_SELECTION).data?.products

    const forms = useMemo(() => {
        return contentBlocks?.filter(form => {
            const formProductCategory = CatalogUtils.getCharacteristicValue(form, Characteristics.ProductCategory.id)
            return !formProductCategory || formProductCategory === productCategory
        })
    }, [contentBlocks, productCategory])

    const characteristics = useMemo(() => {
        const ids = forms?.flatMap(form => CatalogUtils.getCharacteristicValues<string[]>(form, Characteristics.ProjectPropertyCharacteristics.id) ?? [])
        return salesDocumentPropertyCharacteristics.filter(characteristic => ids?.includes(characteristic.id)) ?? []
    }, [forms, salesDocumentPropertyCharacteristics])

    const numberOfInvalidInputs = useSalesDocumentPropertiesForm(characteristics, Characteristics.ProjectDetailsValueRequired.id).numberOfInvalidInputs

    return useMemo(() => ({ forms, numberOfInvalidInputs }), [forms, numberOfInvalidInputs])
}
