import { useRouteError } from "react-router-dom"
import { MainContainer } from "../../components/mainContainer/MainContainer"
import { Box, Stack, Typography } from "@mui/material"
import AppStyles from "../../App.styles"
import Header from "./components/header/Header"
import { L10n } from "@encoway/l10n"

export function RootPageErrorBoundary() {
    const error = useRouteError() as Error
    return (
        <Stack sx={AppStyles.wrapper}>
            <Header withoutTranslations={Object.values(L10n.sources()).length === 0} />
            <MainContainer>
                <Stack spacing={3} mt={10}>
                    <Box width="100px" height="10px" bgcolor="red"></Box>
                    <Typography variant="h1">Oops! An unexpected error occurred</Typography>
                    <Typography variant="h2">{error.name}</Typography>
                    <Box dangerouslySetInnerHTML={{ __html: error.message }} />
                    <Typography>
                        <b>Timestamp:</b> {getTimestamp()}
                    </Typography>
                </Stack>
            </MainContainer>
        </Stack>
    )
}

function getTimestamp() {
    const date = new Date()
    const values = [date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getSeconds()]
    return values.map(toString).join("")
}

function toString(value: number) {
    const s = value.toString()
    return s.length > 1 ? s : "0" + s
}
