import { Box, Grid, Typography } from "@mui/material"
import { L10n } from "@encoway/l10n"
import { ProductCard } from "../productCard/ProductCard"
import TranslationKeys from "../../../../../features/translations/TranslationKeys"
import CatalogUtils from "../../../../../features/catalog/catalog.utils"
import { useSearchParams } from "react-router-dom"
import SalesApi from "../../../../../features/sales/sales.api"
import { CatalogApi } from "../../../../../features/catalog/catalog.api"
import { Characteristics, ProductGroupIds } from "../../../../../features/catalog/catalog.constants"
import { getProductSelection } from "../ProductSelection.utils"
import { ProductSelectionSearchParams } from "../ProductSelection.constants"
import { ErrorAlert } from "../../../../../features/error/components/errorAlert/ErrorAlert"
import ProductGroupCard, { ProductGroupCardSize } from "../productGroupCard/ProductGroupCard"
import useNavigate from "../../../../../router/hooks/useNavigate"
import ProductFilters from "../../../../../features/catalog/components/productFilters/ProductFilters"
import { useProductFilters } from "../hooks/useProductFilters"

interface ProductCatalogProps {
    productGroupId: string
    productGroupIds: string[]
}

export function ProductStandardSelection({ productGroupId, productGroupIds }: Readonly<ProductCatalogProps>) {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const subGroupsQuery = CatalogApi.useSubGroupsQuery(productGroupId)
    const searchValue = searchParams.get(ProductSelectionSearchParams.SEARCH) ?? undefined
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    const productCategory = salesDocument.properties.PRODUCT_CATEGORY

    const productFilters = useProductFilters(productGroupId, searchValue)
    const extendedSearchProductSelection = getProductSelection(ProductGroupIds.Catalog, productFilters.characteristicFilters, true, searchValue)
    const productSelection = getProductSelection(productGroupId, productFilters.characteristicFilters, !!searchValue, searchValue)
    const productsQuery = CatalogApi.useProductsQuery(productSelection)
    const products = productsQuery.currentData?.products ?? []
    const noProductsFoundWithSearch = !!searchValue && products.length === 0
    const productsOfExtendedSearch = CatalogApi.useProductsQuery(extendedSearchProductSelection, { skip: !noProductsFoundWithSearch }).data?.products ?? []
    return (
        <>
            {noProductsFoundWithSearch && (
                <Typography>
                    {L10n.format(
                        productsOfExtendedSearch.length > 0
                            ? TranslationKeys.pages.project.catalog.searchResultsOfCatalog
                            : TranslationKeys.pages.project.catalog.noSearchResultsMessage
                    )}
                </Typography>
            )}
            <Box>
                <Grid container spacing={1}>
                    {subGroupsQuery.isError && <ErrorAlert error={subGroupsQuery.error.message} onRetry={subGroupsQuery.refetch} />}
                    {subGroupsQuery.isFetching
                        ? [1, 2, 3].map(value => (
                              <Grid item key={value}>
                                  <ProductGroupCard size={productGroupIds.length > 1 ? ProductGroupCardSize.Default : ProductGroupCardSize.Big} />
                              </Grid>
                          ))
                        : null}
                    {subGroupsQuery.currentData?.productGroups.map(subGroup => {
                        const characteristicValue = CatalogUtils.getCharacteristicValue<string>(subGroup, Characteristics.ProductCategory.id)
                        return (
                            <Grid paddingTop={0} item key={`${subGroup.id}_${subGroup.hashCode}`}>
                                <ProductGroupCard
                                    productGroup={subGroup}
                                    onSelect={productGroup => navigate.toProductGroup(salesDocument.salesDocumentId, productGroupIds.concat([productGroup.id]))}
                                    size={productGroupIds.length > 1 ? ProductGroupCardSize.Default : ProductGroupCardSize.Big}
                                    disabled={
                                        (!!productCategory && !!characteristicValue && productCategory !== characteristicValue) || !subGroup.hasChildProducts
                                    }
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
            <ProductFilters {...productFilters} />
            <Box>
                <Grid container spacing={4}>
                    {productsQuery.isFetching && (
                        <Grid item>
                            <ProductCard />
                        </Grid>
                    )}
                    {(noProductsFoundWithSearch ? productsOfExtendedSearch : products).map(product => {
                        const characteristicValue = CatalogUtils.getCharacteristicValue<string>(product, Characteristics.ProductCategory.id)
                        return (
                            <Grid item key={product.id}>
                                <ProductCard
                                    product={product}
                                    disabled={!!productCategory && !!characteristicValue && productCategory !== characteristicValue}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </>
    )
}
