import { PropsWithChildren, useCallback, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../../store/store"
import OidcSlice from "../../../../features/oidc/oidc.slice"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import { SalesRoles } from "../../../../features/sales/sales.constants"
import { ContentWithSalesService } from "./ContentWithSalesService"
import { ContentWithCatalogService } from "./ContentWithCatalogService"
import { EnvironmentApi } from "../../../../features/environment/environment.api"
import { SerializedError } from "@reduxjs/toolkit"
import { useIdToken, useToken } from "../abbOpenIdConnect/AbbOpenIdConnect"

const TOKEN_REFRESH_INTERVAL = 25000

const removeSearchParameters = () => {
    if (window.location.search) {
        const baseUri = window.location.protocol + "//" + window.location.host + window.location.pathname
        window.history.replaceState(null, "", baseUri)
    }
}

export const ContentWithToken = (props: PropsWithChildren<unknown>) => {
    const token = useAppSelector(state => state.oidc.token)
    const environmentQuery = EnvironmentApi.useEnvironmentQuery(undefined, { skip: !token })
    const tokenFunction = useToken()
    const idTokenFunction = useIdToken()
    const dispatch = useAppDispatch()

    const updateToken = useCallback(() => {
        try {
            const tokenValue = tokenFunction?.()
            dispatch(OidcSlice.actions.setToken(tokenValue))
            const idTokenValue = idTokenFunction?.()
            dispatch(OidcSlice.actions.setIdToken(idTokenValue))
        } catch (err) {
            const error = new Error(L10n.format(TranslationKeys.error.token.cannotRefresh.body))
            error.name = L10n.format(TranslationKeys.error.token.cannotRefresh.title)
            throw error
        }
    }, [tokenFunction, idTokenFunction, dispatch])

    useEffect(() => {
        updateToken()
        const timer = setInterval(updateToken, TOKEN_REFRESH_INTERVAL)
        return () => clearInterval(timer)
    }, [updateToken])

    useEffect(removeSearchParameters, [])

    useEffect(() => {
        if (
            (environmentQuery.isError && !(environmentQuery.error as SerializedError).code) ||
            (environmentQuery.isSuccess && !environmentQuery.data?.roles.includes(SalesRoles.APPLICATION_USER))
        ) {
            const error = new Error(L10n.format(TranslationKeys.error.login.accessDenied.body))
            error.name = L10n.format(TranslationKeys.error.login.accessDenied.title)
            throw error
        }
    }, [environmentQuery.data?.roles, environmentQuery.error, environmentQuery.isError, environmentQuery.isSuccess])

    return !environmentQuery.data?.roles.includes(SalesRoles.APPLICATION_USER) ? null : (
        <ContentWithSalesService>
            <ContentWithCatalogService>{props.children}</ContentWithCatalogService>
        </ContentWithSalesService>
    )
}
