import { AbbLineItem } from "../../../../../../features/sales/sales.types"

export const getLineItemLabel = (lineItem: AbbLineItem) => {
    if (lineItem.properties.TYPE_CODE) {
        return lineItem.properties.TYPE_CODE
    } else if (lineItem.properties.ARTICLE_ID) {
        return lineItem.properties.ARTICLE_ID
    } else {
        return lineItem.properties.label
    }
}
