import { useCallback, useEffect, useRef } from "react"
import { GridRow, GridRowProps, useGridApiContext } from "@mui/x-data-grid-pro"
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from "react-dnd"
import { getPreviousBomNode } from "./rowUtils"
import SalesApi from "../../../../../../../features/sales/sales.api"
import { BomNode } from "../../LineItemsDataGrid"
import { OverlayStyles, RowPreviewStyles, RowStyles } from "./Row.styles"
import { getDragAndDropHoverPosition } from "../../../../../../../utils/getDragAndDropHoverPosition"
import SalesUtils from "../../../../../../../features/sales/sales.utils"

const DRAG_AND_DROP_ITEM_TYPE = "lineItems"

export default function Row(props: Readonly<GridRowProps>) {
    const apiRef = useGridApiContext()
    const ref = useRef<HTMLDivElement>(null)
    const bomNode = props.row as BomNode
    const salesDocument = SalesApi.endpoints.salesDocument.useQueryState().data!
    const [moveLineItems] = SalesApi.useMoveLineItemsMutation()

    const canDrag = useCallback(
        (monitor: DragSourceMonitor) => {
            const rect = ref.current?.getElementsByClassName("DragAndDropButton")[0]?.getBoundingClientRect()
            const clientOffset = monitor.getClientOffset()
            return !!(rect && clientOffset && clientOffset.x >= rect.x && clientOffset.x <= rect.x + rect.width)
        },
        [ref]
    )

    const canDrop = useCallback(
        (draggedBomNode: BomNode, monitor: DropTargetMonitor) => {
            const hoverPosition = getDragAndDropHoverPosition(monitor, ref)
            return draggedBomNode && SalesUtils.lineItems.canDropLineItem(draggedBomNode, bomNode, salesDocument, hoverPosition)
        },
        [ref, salesDocument, bomNode]
    )

    const drop = useCallback(
        (draggedBomNode: BomNode, monitor: DropTargetMonitor) => {
            const hoverPosition = getDragAndDropHoverPosition(monitor, ref)
            if (hoverPosition === "top") {
                const bomNodes = apiRef.current.getSortedRows() as BomNode[]
                const previousBomNode = getPreviousBomNode(bomNode, bomNodes)
                moveLineItems({
                    selectedLineItemIds: [draggedBomNode.lineItem.lineItemId],
                    parentLineItemId: bomNode.parent?.lineItem.lineItemId,
                    previousLineItem: previousBomNode?.lineItem.lineItemId ?? "FIRST"
                })
            } else if (hoverPosition === "bottom") {
                moveLineItems({
                    selectedLineItemIds: [draggedBomNode.lineItem.lineItemId],
                    parentLineItemId: bomNode.parent?.lineItem.lineItemId,
                    previousLineItem: bomNode.lineItem.lineItemId
                })
            } else if (hoverPosition === "center") {
                moveLineItems({
                    selectedLineItemIds: [draggedBomNode.lineItem.lineItemId],
                    parentLineItemId: bomNode.lineItem.lineItemId
                })
            }
        },
        [apiRef, moveLineItems, bomNode, ref]
    )

    const dropCollect = useCallback(
        (monitor: DropTargetMonitor) => {
            return {
                hover: canDrop(monitor.getItem(), monitor) ? getDragAndDropHoverPosition(monitor, ref) : undefined,
                dropNotAllowed: !!monitor.getItem() && !canDrop(monitor.getItem(), monitor)
            }
        },
        [ref, canDrop]
    )

    const [dragProps, dragRef] = useDrag(
        () => ({
            type: DRAG_AND_DROP_ITEM_TYPE,
            item: bomNode,
            collect: monitor => ({ difference: monitor.getDifferenceFromInitialOffset(), isDragging: monitor.isDragging() }),
            canDrag: canDrag
        }),
        [bomNode, canDrag]
    )

    const [dropProps, dropRef] = useDrop(
        () => ({
            accept: DRAG_AND_DROP_ITEM_TYPE,
            collect: dropCollect,
            drop: drop,
            canDrop: canDrop
        }),
        [drop, dropCollect, canDrop]
    )

    useEffect(() => {
        dragRef(ref.current)
        dropRef(ref.current)
    }, [ref, dragRef, dropRef])
    return (
        <>
            <GridRow {...props} ref={ref} style={RowStyles(dropProps.hover, dragProps.isDragging)} />
            {dropProps.dropNotAllowed && <div style={OverlayStyles(props.index, ref.current)} />}
            {dragProps.isDragging && <GridRow {...props} style={RowPreviewStyles(props.index, ref.current, dragProps.difference)} />}
        </>
    )
}
