import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react"
import { Characteristic, ProductGroup, ProductGroupResult, ProductResult, Selection, SelectProductsResult } from "@encoway/c-services-js-client"
import CatalogUtils from "./catalog.utils"
import { Characteristics } from "./catalog.constants"
import { FetchApi } from "../fetch/fetch.api"
import { AbbProductGroupsResult, DocumentType } from "./catalog.types"
import { catalogQueryFn } from "./catalog.api.utils"

export const CatalogApi = createApi({
    reducerPath: "catalogApi",
    baseQuery: fakeBaseQuery<Error>(),
    endpoints: builder => ({
        products: builder.query<SelectProductsResult, Selection>({
            queryFn: catalogQueryFn({
                query: (selection, service) => service.products(selection),
                spinner: false,
                errorHandling: "none"
            })
        }),

        product: builder.query<ProductResult, string>({
            queryFn: catalogQueryFn({
                query: (productId, service) => service.product(productId),
                errorHandling: "none",
                spinner: false
            })
        }),

        productWithSpinnerAndErrorHandling: builder.query<ProductResult, string>({
            queryFn: catalogQueryFn({
                query: (productId, service) => service.product(productId)
            })
        }),

        productGroup: builder.query<ProductGroupResult, string>({
            queryFn: catalogQueryFn({
                query: (productGroupId, service) => service.group(productGroupId)
            })
        }),

        productGroups: builder.query<ProductGroup[], string[]>({
            queryFn: catalogQueryFn({
                errorHandling: "none",
                query: async (productGroupIds, service) => {
                    const promiseResult = await Promise.allSettled(productGroupIds.map(id => service.group(id)))
                    return promiseResult.reduce((pgs: ProductGroup[], entry) => (entry.status === "fulfilled" ? pgs.concat(entry.value.productGroup) : pgs), [])
                }
            })
        }),

        subGroups: builder.query<AbbProductGroupsResult, string>({
            queryFn: catalogQueryFn({
                spinner: false,
                errorHandling: "none",
                query: (productGroupId, service) => service.subgroups(productGroupId) as Promise<AbbProductGroupsResult>
            })
        }),

        characteristic: builder.query<Characteristic, string>({
            queryFn: catalogQueryFn({
                query: (characteristicId, service) => service.characteristic(characteristicId)
            })
        }),

        documentTypes: builder.query<DocumentType[], string>({
            queryFn: catalogQueryFn({
                query: async (documentTypeId, service, api) => {
                    const productResult = await service.product(documentTypeId)
                    const mediaUri = CatalogUtils.getMediaUri(productResult.product, Characteristics.DocumentTypes.id)!
                    const result = await api.dispatch(FetchApi.endpoints.fetch.initiate({ url: mediaUri })).unwrap()
                    return result[Characteristics.DocumentTypes.id]
                }
            })
        })
    })
})
