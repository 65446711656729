import { useState } from "react"
import { Box, Grid, Stack, Typography } from "@mui/material"
import { CheckCircle, Error } from "@mui/icons-material"
import ExpandButton from "../../../../../components/buttons/expandButton/ExpandButton"
import Icon from "../../../../../components/./icons/Icon"
import ProjectFormStyles from "./ProjectForm.styles"
import { Product } from "@encoway/c-services-js-client"
import { Characteristics } from "../../../../../features/catalog/catalog.constants"
import { SalesDocumentPropertyInput } from "../../../../../features/sales/components/salesDocumentPropertyInput/SalesDocumentPropertyInput"
import { useSalesDocumentPropertiesForm } from "../../../../../features/sales/hooks/useSalesDocumentPropertiesForm"
import { useSalesDocumentPropertyCharacteristics } from "../../../../../features/sales/hooks/useSalesDocumentPropertyCharacteristics"

export interface ProjectFormProps {
    formDefinition: Product
}

export function ProjectForm({ formDefinition }: Readonly<ProjectFormProps>) {
    const [expanded, setExpanded] = useState<boolean>(true)
    const characteristics = useSalesDocumentPropertyCharacteristics(formDefinition, Characteristics.ProjectDetailsOrder.id)
    const form = useSalesDocumentPropertiesForm(characteristics, Characteristics.ProjectDetailsValueRequired.id)
    return (
        <Stack width="100%">
            <Stack direction="row" spacing={3} sx={ProjectFormStyles.header}>
                <ExpandButton expanded={expanded} onToggle={() => setExpanded(prevState => !prevState)} />
                <Typography variant="h3">{formDefinition.name}</Typography>
                {form.isValid ? <Icon component={CheckCircle} color="success" /> : <Icon component={Error} color="error" />}
            </Stack>
            {expanded && (
                <Box component="form" sx={ProjectFormStyles.contentContainer}>
                    <Grid container columns={2} spacing={5}>
                        {characteristics.map(characteristic => (
                            <Grid item key={characteristic.id} xs={1}>
                                <SalesDocumentPropertyInput
                                    characteristic={characteristic}
                                    requiredCharacteristicId={Characteristics.ProjectDetailsValueRequired.id}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
        </Stack>
    )
}
