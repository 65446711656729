import { Characteristic, MediaValue, NumberValue, ValueType } from "@encoway/c-services-js-client"
import CatalogUtils from "../../../catalog/catalog.utils"
import { Characteristics } from "../../../catalog/catalog.constants"
import { CurrencyPermissions } from "../../sales.api.types"
import { TextFieldProps } from "@mui/material/TextField"

export function hasPermissionForPossibleValue(possibleValue: string, characteristicId: string, permissions?: CurrencyPermissions) {
    if (!permissions) {
        return false
    } else {
        const permissionsForCharacteristic: string[] | undefined = permissions[characteristicId as keyof CurrencyPermissions]
        return permissionsForCharacteristic?.includes(possibleValue) ?? true
    }
}

export function getSalesDocumentPropertyInputCharacteristicData(
    characteristic: Characteristic,
    requiredCharacteristicId: string,
    permissions?: CurrencyPermissions
) {
    return {
        valueType: CatalogUtils.getCharacteristicValue<string>(characteristic, Characteristics.ProjectPropertyValueType.id),
        required: CatalogUtils.getCharacteristicValue<NumberValue>(characteristic, requiredCharacteristicId)?.value === 1,
        maxLength: CatalogUtils.getCharacteristicValue<NumberValue>(characteristic, Characteristics.ProjectPropertyValueMaxLength.id)?.value,
        productImage: CatalogUtils.getCharacteristicValue<MediaValue>(characteristic, Characteristics.ProductImage.id),
        shortText: CatalogUtils.getCharacteristicValue<string>(characteristic, Characteristics.ShortText.id),
        longText: CatalogUtils.getCharacteristicValue<string>(characteristic, Characteristics.LongText.id),
        possibleValues: CatalogUtils.getPossibleValues(characteristic, true).filter(pv => hasPermissionForPossibleValue(pv.id, characteristic.id, permissions))
    }
}

export function validateSalesDocumentPropertyInputValue(
    characteristic: Characteristic,
    requiredCharacteristicId: string,
    value?: string | number,
    permissions?: CurrencyPermissions
) {
    const characteristicData = getSalesDocumentPropertyInputCharacteristicData(characteristic, requiredCharacteristicId, permissions)
    const notRequiredOrHasValue = !characteristicData.required || !!value
    if (characteristic.possibleValues) {
        return !characteristicData.required || characteristicData.possibleValues.some(possibleValue => possibleValue.id === value)
    } else if (characteristicData.valueType === Characteristics.ProjectPropertyValueType.possibleValues.EMAIL) {
        return notRequiredOrHasValue && (!value || /\S{1,255}@\S{1,255}\.\S{1,255}/.test(value as string))
    } else {
        return notRequiredOrHasValue
    }
}

export function getInputType(characteristic: Characteristic): TextFieldProps["type"] {
    const propertyValueType = CatalogUtils.getCharacteristicValue<string>(characteristic, Characteristics.ProjectPropertyValueType.id)
    const isPriceListInput = propertyValueType === Characteristics.ProjectPropertyValueType.possibleValues.PRICE_LIST
    switch (characteristic.valueType) {
        case ValueType.Integer:
        case ValueType.Double:
            return "number"
        default:
            return isPriceListInput ? undefined : propertyValueType
    }
}
