import { useAppDispatch, useAppSelector } from "../../../store/store"
import SalesApi from "../sales.api"
import { SalesSlice } from "../sales.slice"
import { useCallback } from "react"

export function useDeleteLineItemsHandler() {
    const lineItemsToDelete = useAppSelector(state => state.sales.lineItemsToDelete)
    const [triggerDeleteLineItems] = SalesApi.useDeleteLineItemsMutation()
    const dispatch = useAppDispatch()

    const askToDeleteLineItems = useCallback(
        (lineItemIds: string[]) => {
            dispatch(SalesSlice.actions.setLineItemsToDelete(lineItemIds))
        },
        [dispatch]
    )

    const cancelDeletingLineItems = useCallback(() => {
        dispatch(SalesSlice.actions.setLineItemsToDelete(undefined))
    }, [dispatch])

    const deleteLineItems = useCallback(async () => {
        if (lineItemsToDelete) {
            await triggerDeleteLineItems(lineItemsToDelete)
        } else {
            console.error("Before deleting line items 'askToDeleteLineItems' must be called.")
        }
    }, [lineItemsToDelete, triggerDeleteLineItems])

    return {
        isAskingToDeleteLineItems: !!lineItemsToDelete,
        askToDeleteLineItems,
        cancelDeletingLineItems,
        deleteLineItems,
        lineItemsToDelete
    }
}
