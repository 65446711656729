import { AppBar, Container, Stack, Toolbar } from "@mui/material"
import AppTitleWithLogo from "./appTitleWithLogo/AppTitleWithLogo"
import MobileMenu from "./menus/mobileMenu/MobileMenu"
import MainMenu from "./menus/mainMenu/MainMenu"
import HeaderStyles from "./Header.styles"
import Language from "./language/Language"
import User from "./user/User"
import HomeIcon from "@mui/icons-material/Home"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../features/translations/TranslationKeys"
import Information from "././information/Information"
import RouterPaths from "../../../../router/constants/RouterPaths"
import { Announcement } from "./announcement/Announcement"
import { useUserInformation } from "../../../../features/oidc/hooks/useUserInformation"
import { HeaderConstants } from "./Header.constants"
import React from "react"
import { usePublicationStatus } from "./usePublicationStatus"
import { DataImportAnimation } from "./dataimportanimation/DataImportAnimation"

interface HeaderProps {
    withoutTranslations?: boolean
}

export default function Header({ withoutTranslations }: Readonly<HeaderProps>) {
    const userInformation = useUserInformation()
    const pages = [
        { label: L10n.format(TranslationKeys.pages.dashboard.pageName), route: RouterPaths.DASHBOARD, icon: <HomeIcon /> },
        { label: L10n.format(TranslationKeys.pages.projects.pageName), route: RouterPaths.PROJECTS }
    ]
    const publicationStatus = usePublicationStatus()
    return (
        <AppBar position="sticky" color="inherit" sx={HeaderStyles.AppBar}>
            <Announcement />
            <Container maxWidth={false}>
                <Toolbar disableGutters sx={{ justifyContent: "space-between", flexDirection: "row" }}>
                    <Stack direction="row" alignItems="center">
                        {userInformation && <MobileMenu menuEntries={pages} />}
                        <AppTitleWithLogo withoutTranslations={withoutTranslations} />
                    </Stack>
                    {userInformation && <MainMenu menuEntries={pages} />}
                    <Stack direction="row" spacing={2} alignItems="center">
                        {publicationStatus.inProgress && <DataImportAnimation />}
                        {userInformation && <Information />}
                        <Language />
                        {userInformation && <User />}
                    </Stack>
                </Toolbar>
            </Container>
            <div id={HeaderConstants.CONFIGURATION_HEADER} />
        </AppBar>
    )
}
