import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { AbbSalesDocumentEntity } from "../../../../../../features/sales/sales.types"
import EditIcon from "@mui/icons-material/Edit"
import AutoStoriesIcon from "@mui/icons-material/AutoStories"
import Icon from "../../../../../../components/icons/Icon"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"

export function PermissionsCell(params: GridRenderCellParams<AbbSalesDocumentEntity>) {
    return params.value.includes("UPDATE") ? (
        <Icon component={EditIcon} tooltipProps={{ title: L10n.format(TranslationKeys.permissions.update) }} />
    ) : (
        <Icon component={AutoStoriesIcon} tooltipProps={{ title: L10n.format(TranslationKeys.permissions.readOnly) }} />
    )
}
