import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../../../features/translations/TranslationKeys"

import { Stack } from "@mui/material"
import { CatalogApi } from "../../../../../../../../features/catalog/catalog.api"
import Dropdown from "../../../../../../../../components/dropdown/Dropdown"
import CatalogUtils from "../../../../../../../../features/catalog/catalog.utils"
import { Characteristics } from "../../../../../../../../features/catalog/catalog.constants"
import { NumberValue } from "@encoway/c-services-js-client"
import Dialog from "../../../../../../../../components/dialog/Dialog"
import SalesApi from "../../../../../../../../features/sales/sales.api"
import { useProjectConfigurationLineItem } from "../../../../hooks/useProjectConfigurationLineItem"
import { ChangeEventHandler } from "react"
import { OidcUtils } from "../../../../../../../../features/oidc/oidc.utils"
import { useUserInformation } from "../../../../../../../../features/oidc/hooks/useUserInformation"
import useConfigurationProduct from "../../../../../../../../features/sales/hooks/useConfigurationProduct"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { SalesSlice } from "../../../../../../../../features/sales/sales.slice"
import { useAppDispatch } from "../../../../../../../../store/store"
import { LineItemProperties } from "../../../../../../../../features/sales/sales.constants"

type Props = {
    close: () => void
    isOpen: boolean
    qr?: string
    error?: string
}

const AbbArDialog = ({ isOpen, close, qr, error }: Props) => {
    const characteristicQuery = CatalogApi.useCharacteristicQuery(Characteristics.RetentionTime.id)
    const lineItem = useProjectConfigurationLineItem()
    const userInformation = useUserInformation()
    const product = useConfigurationProduct()
    const dispatch = useAppDispatch()
    const sortedPossibleValues = characteristicQuery.data
        ? CatalogUtils.getPossibleValues(characteristicQuery.data)
              .map(possibleValue => (possibleValue.value as NumberValue).value as number)
              .sort((a, b) => a - b)
        : []
    const dropdownMenuItems = sortedPossibleValues.map(value => ({
        value: value,
        text: value.toString()
    }))

    const [updateLineItem] = SalesApi.useUpdateLineItemMutation()
    const handleDropdownValueChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = event => {
        const newValue = event.target.value
        if (lineItem) {
            updateLineItem([lineItem.lineItemId, { [LineItemProperties.RETENTION_TIME]: newValue !== "" ? parseInt(newValue) : undefined }])
        }
        dispatch(SalesSlice.actions.setConfigurationIsSavedToFalse())
    }

    const defaultRetentionTime = product
        ? (CatalogUtils.getCharacteristicValue<NumberValue>(product, Characteristics.RetentionTime.id)?.value as number)
        : undefined
    const dialogTextLines = L10n.format(TranslationKeys.pages.project.configuration.visualization.ar.dialogText).split("\n")
    return (
        <Dialog
            title={L10n.format(TranslationKeys.pages.project.configuration.visualization.ar.dialogTitle)}
            onClose={close}
            open={isOpen}
            customDialogActions={{
                actions: [
                    {
                        label: TranslationKeys.pages.project.configuration.visualization.ar.dialogCloseButton,
                        onClick: close,
                        variant: "contained"
                    }
                ]
            }}
        >
            <Stack direction="column" alignItems="center" justifyContent="center">
                <Box>
                    {dialogTextLines.map(line => (
                        <Typography key={line}> {line}</Typography>
                    ))}
                </Box>
                {qr && (
                    <img
                        src={qr}
                        alt={L10n.format(TranslationKeys.pages.project.configuration.visualization.ar.qrCodeAltText)}
                        style={{ width: "200px", height: "200px" }}
                    />
                )}
                {!!error && error}
                {characteristicQuery.data && OidcUtils.hasUpdateRetentionTimeRole(userInformation) && (
                    <Dropdown
                        disableSorting
                        value={lineItem?.properties.retentionTime?.toString() ?? defaultRetentionTime ?? ""}
                        label={characteristicQuery.data.name!}
                        menuItems={dropdownMenuItems}
                        onChange={handleDropdownValueChange}
                    />
                )}
            </Stack>
        </Dialog>
    )
}

export default AbbArDialog
