import { LineItemProperties } from "../../../../features/sales/sales.constants"

export const ProductSelectionSearchParams = {
    SEARCH: "search"
} as const

export const ProductSelectionPosition = {
    LAST: 2147483647 // Java Integer.MAX_VALUE
}

export const DefaultFilter = {
    [LineItemProperties.LINEUP_COMPATIBLE]: 1
}
