import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react"
import { Characteristic, PriceList, UpdateServerResponse } from "@encoway/sales-api-js-client"
import { getAllExpandedLineItems, getLineItems, getLineItemsToExpand, mapLineItemsArgs, salesQueryFn } from "./sales.api.utils"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../translations/TranslationKeys"
import { AbbLineItem, AbbLineItemProperties, AbbSalesDocumentEntity } from "./sales.types"
import {
    AbbProcessExecutionResponse,
    AddLineItemsArgs,
    ApplyConfigurationInterfaceDecisionsArgs,
    ApplyConfigurationInterfaceDecisionsResponse,
    CheckLineupCompatibilityArgs,
    CheckLineupCompatibilityResponse,
    Composition,
    CreateUnitOptionsArgs,
    CurrencyPermissions,
    GetSalesDocumentsArgs,
    GetSalesDocumentsResponse,
    LineItemsArgs,
    LineupVisualization,
    OpenConfigurationArgs,
    PrintArgs,
    PrintResponse,
    ProjectConfiguration,
    UpdateLineItemArgs,
    UpdateSalesDocumentArgs
} from "./sales.api.types"
import { addItemsQuery } from "./queries/addItemsQuery"
import { ConfigurationApi, ConfigurationApiTags } from "../configuration/configuration.api"
import { applyConfigurationInterfaceDecisionsQuery } from "./queries/applyConfigurationInterfaceDecisionsQuery"
import { updateLineItemQueryUpdater } from "./queryDataUpdater/updateLineItemQueryUpdater"
import { Characteristic as CatalogCharacteristic } from "@encoway/c-services-js-client"
import ErrorSlice from "../error/error.slice"

export const SalesApiTags = {
    SALES_DOCUMENTS: "salesDocuments",
    SALES_DOCUMENT: "salesDocument",
    COMPOSITION: "composition",
    LINE_ITEMS: "lineItems",
    LINEUPS: "lineups"
} as const

const SalesApi = createApi({
    reducerPath: "salesApi",
    tagTypes: Object.values(SalesApiTags),
    baseQuery: fakeBaseQuery<Error>(),
    endpoints: builder => ({
        // Queries:
        salesDocuments: builder.query<GetSalesDocumentsResponse, GetSalesDocumentsArgs>({
            providesTags: [SalesApiTags.SALES_DOCUMENTS],
            queryFn: salesQueryFn({
                query: (arg, service) => service.custom.call("/search/projects", arg)
            })
        }),

        salesDocument: builder.query<AbbSalesDocumentEntity, void>({
            providesTags: [SalesApiTags.SALES_DOCUMENT],
            queryFn: salesQueryFn({
                query: (_arg, service) => service.salesDocument.get()
            })
        }),

        salesDocumentPropertyCharacteristics: builder.query<CatalogCharacteristic[], void>({
            providesTags: [SalesApiTags.SALES_DOCUMENTS],
            queryFn: salesQueryFn({
                query: (arg, service) => service.custom.call("/salesdocumentpropertycharacteristics", arg)
            })
        }),

        composition: builder.query<Composition, void>({
            providesTags: [SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: async (_arg, _service, api) => {
                    const lineItemIds = (api.getState() as any).sales.expandedLineItems
                    const ids = ([undefined] as (string | undefined)[]).concat(lineItemIds)
                    const promiseResult: PromiseSettledResult<AbbLineItem[]>[] = await Promise.allSettled(ids.map(id => getLineItems(api, id)))
                    return promiseResult.reduce((result: Record<string, AbbLineItem[]>, entry, index) => {
                        if (entry.status === "fulfilled") {
                            result[ids[index] ?? "ROOT"] = entry.value.filter(
                                x => x.properties.ARTICLE_TYPE !== "PROJECT_OPTIONS" && x.properties.ARTICLE_TYPE !== "LINEUP_OPTIONS"
                            )
                        }
                        return result
                    }, {})
                }
            })
        }),

        lineItems: builder.query<AbbLineItem[], string | void>({
            providesTags: [SalesApiTags.LINE_ITEMS],
            queryFn: salesQueryFn({
                query: (lineItemId, service) => service.lineItems.get(lineItemId ?? undefined)
            })
        }),

        lineups: builder.query<AbbLineItem[], string | void>({
            providesTags: [SalesApiTags.LINEUPS],
            queryFn: salesQueryFn({
                query: (_arg, service) => service.custom.call("/lineup/getAll", {})
            })
        }),

        projectConfiguration: builder.query<ProjectConfiguration, void>({
            providesTags: [SalesApiTags.LINE_ITEMS],
            queryFn: salesQueryFn({
                query: (_arg, service) => service.custom.call("/projectconfiguration/get", {})
            })
        }),

        lineupVisualization: builder.query<LineupVisualization, string>({
            providesTags: [SalesApiTags.LINE_ITEMS],
            queryFn: salesQueryFn({
                query: (lineItemId, service) => service.custom.call("/lineup/visualization/get", { lineItemId })
            })
        }),

        priceLists: builder.query<PriceList[], void>({
            queryFn: salesQueryFn({
                query: (_arg, service) => service.masterData.priceLists()
            })
        }),

        currencyPermissions: builder.query<CurrencyPermissions, void>({
            providesTags: [SalesApiTags.SALES_DOCUMENT],
            queryFn: salesQueryFn({
                query: (_arg, service) => service.custom.call("/permission/currencies/get", {})
            })
        }),

        filterCharacteristics: builder.query<Characteristic[], string>({
            queryFn: salesQueryFn({
                query: (productGroupId, service) => service.masterData.filterCharacteristics(productGroupId)
            })
        }),

        // Mutations:

        createSalesDocument: builder.mutation<AbbSalesDocumentEntity, void>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENTS],
            queryFn: salesQueryFn({
                query: async (_, service) => {
                    return service.salesDocuments.create("quote")
                }
            })
        }),

        openSalesDocument: builder.mutation<void, string>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.COMPOSITION, SalesApiTags.LINE_ITEMS],
            queryFn: salesQueryFn({
                query: async (salesDocumentId, service) => {
                    await service.custom.call("/salesdocuments/getbyids", [salesDocumentId]) // necessary because sales document must be loaded before opening it
                    await service.salesDocuments.open(salesDocumentId, true)
                }
            })
        }),

        closeSalesDocument: builder.mutation<void, void>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT],
            queryFn: salesQueryFn({
                query: (_arg, service) => service.salesDocuments.create("quote")
            })
        }),

        close: builder.mutation<string | null, void>({
            queryFn: salesQueryFn({
                query: (_arg, service) => service.close()
            })
        }),

        deleteSalesDocument: builder.mutation<void, string>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENTS],
            queryFn: salesQueryFn({
                query: (salesDocumentId, service) => service.salesDocuments.delete(salesDocumentId)
            })
        }),

        copySalesDocument: builder.mutation<void, string>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENTS],
            queryFn: salesQueryFn({
                query: async (salesDocumentId, service) => {
                    await service.salesDocuments.copy(salesDocumentId)
                    await service.salesDocument.save()
                }
            })
        }),

        updateSalesDocument: builder.mutation<AbbSalesDocumentEntity, UpdateSalesDocumentArgs>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: (args, service) => service.salesDocument.update(...args)
            })
        }),

        saveSalesDocument: builder.mutation<AbbSalesDocumentEntity, void>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENTS],
            queryFn: salesQueryFn({
                query: (_arg, service) => service.salesDocument.save()
            })
        }),

        setSalesDocumentStatus: builder.mutation<AbbProcessExecutionResponse, string>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENTS, SalesApiTags.SALES_DOCUMENT],
            queryFn: salesQueryFn({
                query: async (status, service, api) => {
                    const state = api.getState() as any
                    const salesDocument = state.salesApi.queries["salesDocument(undefined)"].data
                    const currentStatus = salesDocument.properties.quote_status!
                    const response = await service.process.execute(currentStatus, status)
                    return {
                        messageHeader: response.messageHeader,
                        messageBody: JSON.parse(response.messageBody),
                        possibleSteps: response.possibleSteps
                    } as AbbProcessExecutionResponse
                }
            })
        }),

        expandLineItem: builder.mutation<void, string>({
            invalidatesTags: [SalesApiTags.COMPOSITION],
            queryFn: () => ({ data: undefined })
        }),

        expandAllLineItems: builder.mutation<string[], void>({
            invalidatesTags: [SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: (_arg, _service, api) => getLineItemsToExpand(api)
            })
        }),

        collapseLineItem: builder.mutation<string[], string>({
            invalidatesTags: [SalesApiTags.COMPOSITION],
            queryFn: (lineItemId, api) => ({ data: getAllExpandedLineItems(api, lineItemId) })
        }),

        collapseAllLineItems: builder.mutation<void, void>({
            invalidatesTags: [SalesApiTags.COMPOSITION],
            queryFn: () => ({ data: undefined })
        }),

        addFolder: builder.mutation<UpdateServerResponse, void>({
            invalidatesTags: [SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: (_arg, service) => service.lineItems.addFolder(L10n.format(TranslationKeys.pages.project.composition.newFolderName), undefined, "FIRST")
            })
        }),

        createLineup: builder.mutation<UpdateServerResponse, string>({
            invalidatesTags: [SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION, SalesApiTags.LINEUPS, SalesApiTags.SALES_DOCUMENT],
            queryFn: salesQueryFn({
                errorHandling: "none",
                query: (folderName, service) => service.custom.call("/lineup/create", { folderName })
            })
        }),

        addItems: builder.mutation<UpdateServerResponse | undefined, AddLineItemsArgs>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: addItemsQuery,
                errorHandling: "snackbar",
                spinner: () => L10n.format(TranslationKeys.busy.product.addProducts)
            })
        }),

        checkLineupCompatibility: builder.mutation<CheckLineupCompatibilityResponse, CheckLineupCompatibilityArgs>({
            queryFn: salesQueryFn({
                query: (args, service) => service.custom.call("/lineup/checkCompatibility", args),
                errorHandling: "snackbar",
                spinner: () => L10n.format(TranslationKeys.busy.product.addProducts)
            })
        }),

        addCustomLineItem: builder.mutation<UpdateServerResponse, Partial<AbbLineItemProperties>>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: (properties, service) => service.customLineItems.addCustom({ name: "customLineItem", ...properties, isCustomLineItem: true })
            })
        }),

        updateLineItem: builder.mutation<UpdateServerResponse, UpdateLineItemArgs>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION],
            onQueryStarted: updateLineItemQueryUpdater,
            queryFn: salesQueryFn({
                query: (args, service) => service.custom.call("/lineItem/update", { lineItemId: args[0], properties: args[1] }),
                spinner: () => L10n.format(TranslationKeys.busy.lineItem.update)
            })
        }),

        moveLineItems: builder.mutation<UpdateServerResponse, LineItemsArgs>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: async (args, service) => await service.custom.call("/lineItem/move", mapLineItemsArgs(args))
            })
        }),

        deleteLineItems: builder.mutation<UpdateServerResponse, string[]>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION, SalesApiTags.LINEUPS],
            queryFn: salesQueryFn({
                query: (lineItemIdsToDelete, service) =>
                    service.custom.call(
                        "/lineItem/delete",
                        lineItemIdsToDelete.map(lineItemId => ({ lineItemId: lineItemId }))
                    )
            })
        }),

        duplicateLineItems: builder.mutation<void, LineItemsArgs>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: async (args, service) => {
                    await service.custom.call("/lineItem/duplicate", {
                        ...mapLineItemsArgs(args),
                        copyAppendix: L10n.format(TranslationKeys.lineItem.copyAppendix)
                    })
                }
            })
        }),

        createConfiguration: builder.mutation<string, string>({
            queryFn: salesQueryFn({
                query: async (productId, service, api) => {
                    const result = await service.configuration.create(productId)
                    api.dispatch(ConfigurationApi.util.invalidateTags([ConfigurationApiTags.CONFIGURATION_STATUS]))
                    return result
                }
            })
        }),

        openConfiguration: builder.mutation<string, OpenConfigurationArgs>({
            queryFn: salesQueryFn({
                query: async (args, service, api) => {
                    try {
                        const result: any = await service.configuration.open(...args)
                        api.dispatch(ConfigurationApi.util.invalidateTags([ConfigurationApiTags.CONFIGURATION_STATUS]))
                        return result
                    } catch (e) {
                        api.dispatch(
                            ErrorSlice.actions.set({
                                name: L10n.format(TranslationKeys.components.error.title),
                                message: L10n.format(TranslationKeys.pages.project.configuration.error.message)
                            })
                        )
                        return undefined
                    }
                },
                errorHandling: "none"
            })
        }),

        viewConfiguration: builder.mutation<string, OpenConfigurationArgs>({
            queryFn: salesQueryFn({
                query: async (args, service, api) => {
                    const result = await service.configuration.view(...args)
                    api.dispatch(ConfigurationApi.util.invalidateTags([ConfigurationApiTags.CONFIGURATION_STATUS]))
                    return result
                }
            })
        }),

        addConfiguration: builder.mutation<UpdateServerResponse, void>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: (_arg, service) => service.custom.call("/lineItem/configuration/add", {}),
                spinner: () => L10n.format(TranslationKeys.busy.configuration.add)
            })
        }),

        saveConfiguration: builder.mutation<UpdateServerResponse, void>({
            invalidatesTags: [SalesApiTags.SALES_DOCUMENT, SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: (_arg, service) => service.custom.call("/lineItem/configuration/save", {}),
                spinner: () => L10n.format(TranslationKeys.busy.configuration.save)
            })
        }),

        stopConfiguration: builder.mutation<string, void>({
            queryFn: salesQueryFn({
                query: (_arg, service) => service.configuration.stop(),
                errorHandling: "none"
            })
        }),

        applyConfigurationInterfaceDecisions: builder.mutation<ApplyConfigurationInterfaceDecisionsResponse, ApplyConfigurationInterfaceDecisionsArgs>({
            invalidatesTags: [SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: applyConfigurationInterfaceDecisionsQuery
            })
        }),

        createUnitOptions: builder.mutation<string, CreateUnitOptionsArgs>({
            invalidatesTags: [SalesApiTags.LINE_ITEMS, SalesApiTags.COMPOSITION],
            queryFn: salesQueryFn({
                query: (args, service) => service.custom.call("/lineItem/unitoptions/create", args)
            })
        }),

        print: builder.mutation<PrintResponse, PrintArgs>({
            queryFn: salesQueryFn({
                query: (args, service) => service.custom.call("/print", args)
            })
        })
    })
})

export default SalesApi
