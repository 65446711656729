import { DragAndDropHoverPosition } from "../../../../../../utils/getDragAndDropHoverPosition"
import theme from "../../../../../../App.theme"

export const TreeItemStyles = {
    treeItem: {
        ".MuiTreeItem-content": {
            py: 0,
            px: 0
        },
        ".MuiTreeItem-iconContainer": {
            display: "none !important"
        },
        ".MuiCollapse-root": {
            px: 0
        }
    },
    treeItemLabel: {
        columnGap: 2,
        display: "flex",
        justifyContent: "space-between",
        py: 0.5,
        minWidth: "22rem"
    },
    treeItemIcon: {
        py: 0.2,
        columnGap: 1
    },
    actionContainer: {
        flex: "0 1 auto",
        flexDirection: "row",
        alignItems: "center",
        gap: 0.2
    },
    treeItemLabelDragAndDrop: (dragAndDropHoverPosition: DragAndDropHoverPosition, isDragging: boolean) => ({
        borderTopWidth: "2px",
        borderTopColor: dragAndDropHoverPosition === "top" ? theme.palette.secondary.light : "transparent",
        borderBottomWidth: "2px",
        borderBottomColor: dragAndDropHoverPosition === "bottom" ? theme.palette.secondary.light : "transparent",
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderStyle: "solid",
        backgroundColor: dragAndDropHoverPosition === "center" ? theme.palette.secondary.light : "transparent",
        visibility: isDragging ? "hidden" : "visible"
    }),
    treeItemLabelDropNotAllowOverlay: (treeItem: HTMLElement | null) => ({
        position: "absolute",
        top: treeItem ? treeItem.offsetTop : 0,
        left: treeItem?.offsetLeft ?? 0,
        zIndex: theme.zIndex.tooltip - 1,
        cursor: "not-allowed",
        width: treeItem?.getBoundingClientRect().width ?? 0,
        height: treeItem?.getBoundingClientRect().height ?? 0
    }),
    treeItemLabelDragged: (treeItem: HTMLElement | null, yDragDifference?: number) => ({
        backgroundColor: theme.palette.background.paper,
        opacity: 0.8,
        position: "absolute",
        top: treeItem && yDragDifference ? treeItem.offsetTop + yDragDifference : 0,
        zIndex: theme.zIndex.tooltip,
        pointerEvents: "none",
        width: treeItem?.getBoundingClientRect().width ?? 0
    }),
    menu: {
        "& li": {
            display: "flex",
            columnGap: 1
        }
    },
    iconButton: {
        color: theme.palette.text.primary,
        padding: 0,
        height: "1em",
        ":hover": {
            backgroundColor: theme.palette.background.default
        }
    },
    textEllipsis: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
    },
    infoContainer: {
        display: "flex",
        alignItems: "center",
        columnGap: 2,
        flexDirection: "row",
        flex: 1
    },
    labelContainer: {
        flexDirection: "column",
        flex: 1,
        display: "flex"
    },
    nameLabel: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        textWrap: "nowrap"
    },
    subNameLabel: {
        color: theme.palette.text.secondary,
        overflow: "hidden",
        textOverflow: "ellipsis",
        textWrap: "nowrap"
    }
}
