import { PropsWithChildren } from "react"
import { ContentWithToken } from "./ContentWithToken"
import { PublicEnvironment } from "../../../../features/environment/environment.types"
import { AbbOpenIdConnect } from "../abbOpenIdConnect/AbbOpenIdConnect"

type AuthorizedContentProps = PropsWithChildren<Pick<PublicEnvironment, "clientId" | "issuerUri" | "expectedIssuerUri" | "discoveryUri">>

export default function AuthorizedContent({ clientId, issuerUri, discoveryUri, expectedIssuerUri, children }: AuthorizedContentProps) {
    /**
     * Removes the state and scope parameter from the url. The ABB CIAM adds a state that is not expected.
     * As discussed with Sameer Nahar, this is by design and we should ignore the added state by the ABB CIAM.
     * @param props the props with children.
     */
    return (
        <AbbOpenIdConnect
            clientId={clientId}
            realm=""
            authenticationServerUrl={discoveryUri}
            expectedIssuerUrl={expectedIssuerUri || issuerUri}
            redirectUri={window.location.protocol + "//" + window.location.host + window.location.pathname}
        >
            <ContentWithToken>{children}</ContentWithToken>
        </AbbOpenIdConnect>
    )
}
