import { Visualization } from "@encoway/visual-editor"
import { queryFn, QueryFnOptions } from "../../store/queryFn"
import { Environment } from "../environment/environment.types"
import { AbbLineItem } from "../sales/sales.types"
import { NumberValue, Product } from "@encoway/c-services-js-client"
import { LineItemProperties } from "../sales/sales.constants"
import CatalogUtils from "../catalog/catalog.utils"
import { Characteristics } from "../catalog/catalog.constants"

type VisualizationQueryFnOptions<Response, Arg> = Omit<QueryFnOptions<Response, Arg, Visualization>, "serviceSelector">

export class VisualizationApiUtils {
    static queryFn<Response, Arg>(options: VisualizationQueryFnOptions<Response, Arg>) {
        return queryFn<Response, Arg, Visualization>({
            serviceSelector: state => state.visualization.visualization,
            ...options
        })
    }

    static getRetentionTime(environment?: Environment, lineItem?: AbbLineItem, product?: Product): number {
        if (lineItem?.properties?.[LineItemProperties.RETENTION_TIME]) {
            return Number(lineItem.properties[LineItemProperties.RETENTION_TIME])
        } else if (product && CatalogUtils.getCharacteristicValue<NumberValue>(product, Characteristics.RetentionTime.id)?.value) {
            return Number(CatalogUtils.getCharacteristicValue<NumberValue>(product, Characteristics.RetentionTime.id)?.value)
        } else if (environment?.defaultArRetentionTime) {
            return Number(environment.defaultArRetentionTime)
        } else {
            return 1
        }
    }
}
