import { InfoIcon } from "@fluentui/react-icons-mdl2"
import { FontIcon } from "@fluentui/react/lib/Icon"
import { Link } from "@fluentui/react/lib/Link"
import { IProcessedStyleSet, registerIcons } from "@fluentui/react/lib/Styling"
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities"
import React, { useState } from "react"
import { InfoButtonProps, InfoButtonStyles } from "@encoway/cui-configurator-components"
import { IInfoButtonStyles } from "@encoway/cui-configurator-components/src/components/InfoButton/InfoButton.types"

// CUSTOMIZING
import { CatalogEntityInfoDrawerContent } from "../../../../../../../../features/catalog/components/catalogEntityInfoDrawerContent/CatalogEntityInfoDrawerContent"
import { Drawer } from "../../../../../../../../components/drawer/Drawer"
import { Box } from "@mui/material"

const INFO = "Info"
registerIcons(
    {
        icons: {
            [INFO]: <InfoIcon />
        }
    },
    { disableWarnings: true }
)

const isInfoAvailable = (props: InfoButtonProps) => {
    if (props.isInfoAvailable) {
        return props.isInfoAvailable(props)
    }

    if (!props.data) {
        return false
    }

    return props.data.longText || props.data.shortText || (props.data.imageUrl && !props.hideImage)
}

const getHeaderText = (data: Record<string, unknown>) => {
    return (data.translatedName || data.translatedValue) as string
}

/**
 * CUSTOMIZED InfoButton component that uses the CatalogEntityInfoDrawer Component instead of the Panel component.
 * The customization tries to accomplish a satisfying rendering if a html-table is embedded in the longText prop and that would result in a scrollable container.
 *
 * The InfoButton component renders additional information of a ParameterTO as a MS Fluent Link, which opens a MS Fluent Panel.
 * The panel opens on click (or Enter) and renders the image, short text and long text of the given position.
 *
 * Links:
 * - [Checkout the code](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/InfoButton/InfoButton.tsx)
 * - [InfoButtonStyles](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/InfoButton/InfoButton.styles.ts)
 * - [InfoButtonProps](https://gitlab.encoway-services.de/pd/dev/encoway-cpq/-/blob/master/cui/features/configurator-components/src/components/InfoButton/InfoButton.types.ts)
 * - [MS Fluent Link](https://developer.microsoft.com/de-DE/fluentui#/controls/web/link)
 * - [MS Fluent Panel](https://developer.microsoft.com/de-DE/fluentui#/controls/web/panel)
 *
 * @visibleName InfoButton
 */
const IInfoButton = (props: InfoButtonProps) => {
    const [isPanelOpen, setPanelOpen] = useState(false)
    const { styles, theme } = props
    const classNames = classNamesFunction()(styles, theme) as IProcessedStyleSet<IInfoButtonStyles>

    if (!isInfoAvailable(props)) {
        return null
    }

    return (
        <Box className={classNames.root} onClick={event => event.stopPropagation()}>
            <Link
                as={"a"} // otherwise this link will be displayed as a button
                className={classNames.iconButton}
                onClick={event => {
                    setPanelOpen(true)
                    event.stopPropagation()
                }}
                onKeyPress={event => {
                    // Prevent side effects by keyPress events
                    event.preventDefault()
                }}
                onKeyUp={event => {
                    if (event.key === "Enter" || event.key === " ") {
                        setPanelOpen(true)
                    }
                    event.preventDefault()
                }}
                tabIndex={0}
            >
                <FontIcon aria-label="Info" iconName={INFO} className={classNames.icon} />
            </Link>

            <Drawer open={isPanelOpen} onClose={() => setPanelOpen(false)}>
                <CatalogEntityInfoDrawerContent
                    name={getHeaderText(props.data as unknown as Record<string, unknown>)}
                    shortText={props.data.shortText}
                    longText={props.data.longText}
                    imageUrl={props.mediaLink?.(props.data.imageUrl ?? "")}
                />
            </Drawer>
        </Box>
    )
}

export const CuiInfoButtonWithContentSizeFitting = styled(IInfoButton, InfoButtonStyles)
CuiInfoButtonWithContentSizeFitting.displayName = "InfoButton"
