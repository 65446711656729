import { useEffect, useState } from "react"
import { useSettings } from "../../../../hooks/useSettings"

export type PublicationStatusModel = {
    inProgress: boolean
}

export const usePublicationStatus = () => {
    const settings = useSettings()
    const [publicationStatus, setPublicationStatus] = useState<PublicationStatusModel>({ inProgress: false })
    useEffect(() => {
        const eventSource = new EventSource(`${settings.configuration.baseUrl}/api/features/public/sse-publicationstatus`)
        eventSource.onerror = e => console.error("failed to monitor publication status", e)
        eventSource.onmessage = event => {
            try {
                setPublicationStatus(JSON.parse(event.data))
            } catch (err) {
                console.error("failed to parse publication status", err)
            }
        }
        return () => {
            eventSource.close()
        }
    }, [settings])
    return publicationStatus
}
