import { useCallback } from "react"
import { VisualizationApi } from "../visualization.api"
import useConfigurationProduct from "../../sales/hooks/useConfigurationProduct"
import useCuiAppSettings from "../../../pages/projectPage/components/projectConfiguration/hooks/useCuiAppSettings"
import { useProjectConfigurationLineItem } from "../../../pages/projectPage/components/projectConfiguration/hooks/useProjectConfigurationLineItem"

export function useLazyArQuery() {
    const [loadAr, result] = VisualizationApi.useLazyArQuery()
    const cuiAppSettings = useCuiAppSettings()
    const product = useConfigurationProduct()
    const lineItem = useProjectConfigurationLineItem()

    const fetch = useCallback(
        (lineItemId: string) => loadAr({ product: product!, lineItemId: lineItemId, cuiAppSettings: cuiAppSettings!, lineItem: lineItem }),
        [cuiAppSettings, loadAr, product, lineItem]
    )

    return { fetch, result }
}
