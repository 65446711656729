import React, { createContext, useContext, useMemo, ReactNode } from "react"

import { IOpenIdConnectProps, IAuthenticationContext } from "./AbbOpenIdConnect.types"
import { useAbbOpenIdAuthentication } from "./useAbbOpenIdAuthentication"
import { AuthTokenProvider } from "@encoway/sales-showroom-auth"

const AuthenticationContext = createContext<IAuthenticationContext | undefined>(undefined)

export const useToken: () => AuthTokenProvider | undefined = () => {
    return useContext(AuthenticationContext)?.accessToken
}

export const useIdToken: () => AuthTokenProvider | undefined = () => {
    return useContext(AuthenticationContext)?.idToken
}

const Waiting = (props: { notifyWaiting?: () => ReactNode }) => {
    if (props.notifyWaiting) {
        return <>{props.notifyWaiting()}</>
    }
    return null
}
const AuthenticationError = (props: { notifyError?: (message: string) => ReactNode; message: string }) => {
    console.error("authenticationError", props.message)
    if (props.notifyError) {
        return <>{props.notifyError(props.message)}</>
    }
    return null
}

export const AbbOpenIdConnect = (props: IOpenIdConnectProps) => {
    const { tokenFunction, authenticationError } = useAbbOpenIdAuthentication(props, props.skip)
    const authenticationContext = useMemo(
        () => ({
            accessToken: tokenFunction?.accessToken,
            idToken: tokenFunction?.idToken
        }),
        [tokenFunction?.accessToken, tokenFunction?.idToken]
    )
    if (!props.skip) {
        if (authenticationError) {
            return <AuthenticationError notifyError={props.notifyError} message={authenticationError.message} />
        }
        if (!tokenFunction?.accessToken) {
            return <Waiting notifyWaiting={props.notifyWaiting} />
        }
    }
    return <AuthenticationContext.Provider value={authenticationContext}>{props.children}</AuthenticationContext.Provider>
}

AbbOpenIdConnect.displayName = "AbbOpenIdConnect"
