import { IconButton } from "@mui/material"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import React from "react"
import { NextArrowStyles } from "./NextArrow.styles"

export const NextArrow = (props: any) => {
    const { style, onClick, className } = props
    const disabled = className.includes("slick-disabled")
    return disabled ? null : (
        <IconButton onClick={onClick} style={NextArrowStyles.root(style)} disabled={disabled}>
            <ArrowForwardIcon />
        </IconButton>
    )
}
