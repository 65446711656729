import { GridValueGetter } from "@mui/x-data-grid-pro"
import { L10n } from "@encoway/l10n"
import TranslationKeys from "../../../../../../features/translations/TranslationKeys"

export const getPlusCodeCellValue: GridValueGetter = (_value, row) => {
    const typeCode = row.lineItem.properties.TYPE_CODE
    if (typeCode != null) {
        return typeCode.startsWith("+") ? L10n.format(TranslationKeys.lineItem.typeCodePlaceholder) + typeCode : typeCode
    } else if (row.lineItem.properties.PLUSCODE) {
        return row.lineItem.properties.PLUSCODE.startsWith("+") ? row.lineItem.properties.PLUSCODE : "+" + row.lineItem.properties.PLUSCODE
    } else {
        return row.lineItem.properties.shortText
    }
}
